import {
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalOverlay,
  SimpleGrid,
  useDisclosure,
  Text,
  Spacer,
  Flex,
} from '@chakra-ui/react';
import { Top as ITop } from 'centerpiece-algorithm-client';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import Content from '../components/reusable/layout/content';
import Page from '../components/reusable/layout/page';
import PageHeader from '../components/reusable/layout/page-header';
import AddTable from '../components/tops/add-top';
import Top from '../components/tops/top';
import { RootState } from '../store/store';
import { PlusSquareIcon } from '@chakra-ui/icons';
import { TopsHelper } from '../util/tops.function';
import GuestStatistics from '../components/reusable/statistics';
import useMobile from '../hooks/useMobile';

const rawTopsSelector = (state: RootState) => state.tops;
// Create a memoized selector using createSelector
export const topsSelector = createSelector([rawTopsSelector], (tops) => [...tops]);

const Tops: React.FunctionComponent = () => {
  const isMobile = useMobile();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const tops = useSelector(topsSelector);

  const renderNoDataMessage = () => {
    return (
      <Box textAlign='center' marginTop='8'>
        <Text fontSize='xl' fontWeight='bold' color='gray.500'>
          You can click on <Text as='i'>Add Table</Text> to add your first table
        </Text>
      </Box>
    );
  };

  const renderTopsContent = () => {
    if (tops.length === 0) {
      return renderNoDataMessage();
    }

    return (
      <SimpleGrid minChildWidth={[300, 500]} spacing='40px'>
        {tops
          .map((top: ITop) => top)
          .sort((a, b) => TopsHelper.compare(a, b))
          .map((top: ITop) => (
            <Top {...top} key={top.id} />
          ))}
      </SimpleGrid>
    );
  };

  return (
    <>
      <Page>
        <Content>
          <PageHeader>Manage Tables</PageHeader>
          <Flex>
            <ButtonGroup>
              <Button leftIcon={<PlusSquareIcon />} onClick={onOpen}>
                Add Table
              </Button>
            </ButtonGroup>
            <Spacer />
            {!isMobile && <GuestStatistics />}
          </Flex>
          {renderTopsContent()}
        </Content>
      </Page>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <AddTable onSubmit={onClose} />
      </Modal>
    </>
  );
};

export default Tops;
