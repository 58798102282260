import { ButtonGroup, Icon, IconButton, Tooltip, useBoolean } from '@chakra-ui/react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { FaUsers, FaChessBoard, FaChair, FaBars } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { InfoIcon } from '@chakra-ui/icons';

const MotionButtonGroup = motion(ButtonGroup);

const NavigationMenu: React.FunctionComponent = () => {
  const [isOpen, setIsOpen] = useBoolean(true);

  return (
    <MotionButtonGroup
      className='print-hide'
      position='fixed'
      transform={`translate(${isOpen ? '-50%' : '-1px'}, 0)`}
      bottom={8}
      p={2}
      bgColor='gray.600'
      borderRadius={10}
      borderLeftRadius={isOpen ? 10 : 0}
      initial={{ left: '50%' }}
      animate={{ left: isOpen ? '50%' : 0 }}
    >
      <IconButton
        aria-label='Guests'
        icon={<Icon as={FaBars} />}
        onClick={setIsOpen.toggle}
        variant={isOpen ? 'outline' : 'solid'}
        color={isOpen ? 'white' : 'whiteAlpha'}
      />
      {isOpen && (
        <>
          <Link to='/guests'>
            <Tooltip label='Guests' hasArrow gutter={16}>
              <IconButton aria-label='Guests' icon={<Icon as={FaUsers} />} />
            </Tooltip>
          </Link>
          <Link to='/tables'>
            <Tooltip label='Tables' hasArrow gutter={16}>
              <IconButton aria-label='Tables' icon={<Icon as={FaChair} />} />
            </Tooltip>
          </Link>
          <Link to='/plan'>
            <Tooltip label='Seating Plan' hasArrow gutter={16}>
              <IconButton aria-label='Seating Plan' icon={<Icon as={FaChessBoard} />} />
            </Tooltip>
          </Link>
          <Link to='/about'>
            <Tooltip label='About' hasArrow gutter={16}>
              <IconButton aria-label='About' icon={<Icon as={InfoIcon} />} />
            </Tooltip>
          </Link>
        </>
      )}
    </MotionButtonGroup>
  );
};

export default NavigationMenu;
