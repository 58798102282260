// GuestList.tsx
import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  useColorMode,
} from '@chakra-ui/react';
import { Guest } from 'centerpiece-algorithm-client';
import GuestConstraints from './guest-constraints';
import GuestItem from './guest-item';
import { ActionEnum } from '../../types/action.enum';

interface GuestListProps {
  guests: Guest[];
  currentGuestId: string | null;
  handleGuestClick: (guestId: string, action: ActionEnum, $event: React.MouseEvent) => void;
  setCurrentGuestId: React.Dispatch<React.SetStateAction<string | null>>;
}

const GuestList: React.FC<GuestListProps> = ({
  guests,
  currentGuestId,
  handleGuestClick,
  setCurrentGuestId,
}) => {
  const { colorMode } = useColorMode();

  return (
    <Accordion allowToggle>
      {guests.map((guest) => (
        <AccordionItem key={guest.id} onMouseEnter={() => setCurrentGuestId(guest.id)}>
          <AccordionButton
            as='div'
            display='flex'
            cursor={!guest.isSpecial ? 'pointer' : 'default'}
            minHeight={'48px'}
            _expanded={{
              backgroundColor: `${colorMode === 'light' ? 'gray.100' : 'gray.800'}`,
            }}
          >
            <AccordionIcon marginRight={4} display={guest.isSpecial ? 'none' : 'block'} />
            <GuestItem
              guest={guest}
              currentGuestId={currentGuestId}
              handleGuestClick={handleGuestClick}
            />
          </AccordionButton>
          {!guest.isSpecial && (
            <AccordionPanel px={20} paddingLeft={3} padding={3} paddingRight={4}>
              <GuestConstraints guestId={guest.id} />
            </AccordionPanel>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default GuestList;
