import { Button } from '@chakra-ui/react';
import { PlanRequest, SolverStatus } from 'centerpiece-algorithm-client';
import Content from '../components/reusable/layout/content';
import Page from '../components/reusable/layout/page';
import PageHeader from '../components/reusable/layout/page-header';
import { api } from '../util/api';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from '../store/store';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { __ } from '../util/object.function';
import { useDispatch } from 'react-redux';
import { resultActions } from '../store/slices/result-slice';
import TopsOverview from '../components/assignments/tops-overview';
import { ResultVisualization } from '../types/result-visualization.enum';

const Plan: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const guests = useSelector((state: RootState) => state.guests);
  const guestConstraints = useSelector((state: RootState) => state.guestConstraints);
  const tops = useSelector((state: RootState) => state.tops);
  const topConstraints = useSelector((state: RootState) => state.topConstraints);
  const assignments = useSelector((state: RootState) => state.result?.assignments);
  const solverStatus = useSelector((state: RootState) => state.result?.solverStatus);
  const [isLoading, setIsLoading] = useState(false);
  const [isErroneous, setIsErroneous] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    dispatch(resultActions.clear());
    const request: PlanRequest = {
      dataKey: uuidv4(),
      guestConstraints: guestConstraints,
      topConstraints: topConstraints,
      guests: guests,
      tops: tops,
      planConfig: {},
    };
    try {
      const response = await api.postOptimizeSeatingPlan(request);
      const solverStatus = response.data?.solverStatus;
      if (solverStatus === SolverStatus.feasible || solverStatus === SolverStatus.optimal) {
        let constraintReport = null;
        // fetch report if solver status is only feasible
        if (solverStatus === SolverStatus.feasible) {
          const reportResponse = await api.postCreateConstraintReport({
            plan_request: request,
            assignments: response.data?.assignments!,
          });
          constraintReport = reportResponse.data;
        }

        dispatch(
          resultActions.replace({
            solverStatus: solverStatus,
            assignments: __.IsNullOrUndefinedOrEmpty(response.data?.assignments)
              ? []
              : response.data.assignments!,
            constraintReport: constraintReport,
            resultVisualization: ResultVisualization.CARDS,
          })
        );
      } else {
        dispatch(
          resultActions.replace({
            solverStatus: solverStatus,
            assignments: [],
            constraintReport: null,
            resultVisualization: ResultVisualization.CARDS,
          })
        );
      }

      setIsLoading(false);
    } catch (err: any) {
      console.error(err);
      setIsLoading(false);
      setIsErroneous(true);
    }
  };

  return (
    <Page>
      <Content>
        <PageHeader>Seating Plan</PageHeader>
        <Button
          className='print-hide'
          isLoading={isLoading}
          loadingText='Generating Plan'
          onClick={handleClick}
          spinnerPlacement='end'
        >
          Create Seating Plan
        </Button>
        {(!__.IsNullOrUndefinedOrEmpty(assignments) ||
          solverStatus === SolverStatus.infeasible) && <TopsOverview></TopsOverview>}
        {isErroneous && <p>There was a problem</p>}
      </Content>
    </Page>
  );
};

export default Plan;
