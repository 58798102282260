import {
  Editable,
  EditableInput,
  EditablePreview,
  Heading,
  IconButton,
  Stack,
  Tooltip,
} from '@chakra-ui/react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import EditableControls from '../reusable/editable/editable-controls';
import { topsActions } from '../../store/slices/tops-slice';
import { StarIcon } from '@chakra-ui/icons';
import useMobile from '../../hooks/useMobile';

interface ITopNameProps {
  topId: string;
}

const TopName: React.FunctionComponent<ITopNameProps> = (props) => {
  const isMobile = useMobile();
  const { topId } = props;
  const topName = useSelector((state: RootState) =>
    state.tops.find((top) => top.id === topId)
  )?.name;
  const topIsSpecial = useSelector((state: RootState) =>
    state.tops.find((top) => top.id === topId)
  )?.isSpecial;
  const dispatch = useDispatch();

  const [value, setValue] = React.useState(topName);

  React.useEffect(() => {
    setValue(topName);
  }, [topName]);

  return (
    <Stack spacing={3} direction='row'>
      <Tooltip
        label='A special table (e.g. Head Table, Kids Table) is intended specifically for special guests (e.g. bridesmaids, children) and will not be considered by the seating algorithm.'
        aria-label='tooltip'
        placement='right'
      >
        <IconButton
          style={{ marginTop: '9px' }}
          aria-label='Delete guest'
          variant='outline'
          size={'sm'}
          icon={<StarIcon />}
          color={topIsSpecial ? 'pastelMint' : 'pastelRed'}
          onClick={() => {
            dispatch(topsActions.update({ id: topId, top: { isSpecial: !topIsSpecial } }));
          }}
        />
      </Tooltip>
      <Editable
        defaultValue='Table Name'
        display='flex'
        alignItems='center'
        gap={1}
        value={value}
        onChange={(nextValue) => setValue(nextValue)}
        onSubmit={(nextValue) =>
          dispatch(topsActions.update({ id: topId, top: { name: nextValue } }))
        }
      >
        <Heading size='md' as={EditablePreview} maxWidth={isMobile ? '200px' : '220px'} />
        <EditableInput px='4' maxWidth='400px' width='100%' />
        <EditableControls tooltipLabel='Edit Table Name' />
      </Editable>
    </Stack>
  );
};

export default TopName;
