import React from 'react';
import { Box, Grid, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

const GuestStatistics: React.FC = () => {
  const guests = useSelector((state: RootState) => state.guests);
  const tops = useSelector((state: RootState) => state.tops);

  const totalGuests = guests.filter((guest) => guest.isSpecial === false).length;
  const tableCapacity = tops
    .filter((top) => !top.isSpecial)
    .reduce((sum, top) => {
      return sum + Number.parseInt(top.maxCapacity.toString());
    }, 0);

  const specialTotalGuests = guests.filter((guest) => guest.isSpecial === true).length;
  const specialTableCapacity = tops
    .filter((top) => top.isSpecial === true)
    .reduce((sum, top) => {
      return sum + Number.parseInt(top.maxCapacity.toString());
    }, 0);

  return (
    <Box textAlign='right'>
      <Grid templateColumns='1fr 1fr' gap={6}>
        <Box>
          <Text fontSize='sm' color='gray.500'>
            Normal Guests: {totalGuests}
          </Text>
          <Text fontSize='sm' color='gray.500'>
            Special Guests: {specialTotalGuests}
          </Text>
          <Text fontSize='sm' color='gray.500'>
            Total Guests: {specialTotalGuests + totalGuests}
          </Text>
        </Box>
        <Box>
          <Text fontSize='sm' color='gray.500'>
            Normal Table Capacity: {tableCapacity}
          </Text>
          <Text fontSize='sm' color='gray.500'>
            Special Table Capacity: {specialTableCapacity}
          </Text>
          <Text fontSize='sm' color='gray.500'>
            Total Table Capacity: {specialTableCapacity + tableCapacity}
          </Text>
        </Box>
      </Grid>
    </Box>
  );
};

export default GuestStatistics;
