import { Guest, GuestConstraint, Top, TopConstraint } from 'centerpiece-algorithm-client';
import { __ } from './object.function';

export const GuestsHelper = {
  /**
   * Compares two guests by their names.
   * @param a
   * @param b
   * @param considerParty
   * @returns
   */
  compare(a: Guest, b: Guest, considerParty = false): number {
    if (considerParty) {
      const partyComparison = a.partyId.localeCompare(b.partyId);
      if (partyComparison !== 0) {
        return partyComparison;
      }
    }
    return this.getName(a).localeCompare(this.getName(b));
  },

  /**
   * Returns a guest displayName
   * @param guest
   * @returns
   */
  getName(guest: Guest): string {
    return __.IsNullOrUndefinedOrEmpty(guest.name) ? '' : guest.name!;
  },

  /**
   * Determines if the guest should be filtered out based on the guest constraints.
   * @param guestToFilter
   * @param partyId
   * @param guestConstraints
   * @param currentGuest
   * @returns
   */
  filterByGuestConstraints(
    guestToFilter: Guest,
    partyId: string,
    guestConstraints: GuestConstraint[],
    currentGuest: Guest,
    guestsByParty: { [partyId: string]: Set<string> }
  ): boolean {
    const guestsOfSamePartyAsFilterGuest = guestsByParty[guestToFilter.partyId];
    return (
      // if we have a partyFilter, the guest have to be of the same party
      (!__.IsNullOrUndefinedOrEmpty(partyId) ? guestToFilter.partyId === partyId : true) &&
      // there cannot be any guest constraint with any of the guests that belong to the same party as guestToFilter
      !guestConstraints.some(
        constraint =>
          guestsOfSamePartyAsFilterGuest.has(constraint.guestId1!) ||
          guestsOfSamePartyAsFilterGuest.has(constraint.guestId2!)
      ) &&
      guestToFilter.id !== currentGuest.id &&
      guestToFilter.isSpecial === false
    );
  },
  /**
   * Determines if the guest should be filtered out based on the top constraints.
   * @param guest
   * @param partyId
   * @param topConstraints
   * @returns
   */
  filterByTopConstraints(
    guest: Guest,
    partyId: string,
    topConstraints: TopConstraint[],
    guestsOfSameParty: Set<string>,
    top: Top,
    guestsOfTop: Set<string>
  ): boolean {
    if (guest.isSpecial !== top.isSpecial && !__.IsNullOrUndefined(top.isSpecial)) {
      return false;
    }
    return !__.IsNullOrUndefinedOrEmpty(partyId)
      ? guest.partyId === partyId
      : true &&
      !topConstraints.find(constraint => guestsOfSameParty.has(constraint.guestId!)) &&
      guestsOfTop.size + guestsOfSameParty.size <= Number.parseInt(top.maxCapacity.toString());
  },

  /**
   * Generates a dictionary of guests by partyId.
   * @param guests
   * @returns
   */
  generateGuestIdsByPartyId(guests: Guest[]): { [partyId: string]: Set<string> } {
    const guestsByPartyId: Record<string, Set<string>> = guests.reduce((dictionary, guest) => {
      const { partyId } = guest;
      if (!dictionary[partyId]) {
        dictionary[partyId] = new Set();
      }
      dictionary[partyId].add(guest.id);
      return dictionary;
    }, {} as Record<string, Set<string>>);
    return guestsByPartyId;
  },

  /**
   * Generates a dictionary of guests by partyId.
   * @param guests
   * @returns
   */
  generateGuestsByPartyId(guests: Guest[]): { [partyId: string]: Guest[] } {
    const guestsByPartyId: Record<string, Guest[]> = guests.reduce((dictionary, guest) => {
      const { partyId } = guest;
      if (!dictionary[partyId]) {
        dictionary[partyId] = [];
      }
      dictionary[partyId].push(guest);
      return dictionary;
    }, {} as Record<string, Guest[]>);
    return guestsByPartyId;
  },
};
