import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  Switch,
} from '@chakra-ui/react';
import { Guest } from 'centerpiece-algorithm-client';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { v4 as uuidv4 } from 'uuid';
import useMobile from '../../hooks/useMobile';
import { guestConstraintsActions } from '../../store/slices/guest-constraints-slice';
import { guestsActions } from '../../store/slices/guests-slice';
import { partiesActions } from '../../store/slices/parties-slice';
import { topConstraintsActions } from '../../store/slices/top-constraints-slice';
import { RootState } from '../../store/store';
import { GuestsHelper } from '../../util/guests.function';
import { __ } from '../../util/object.function';
import { PartiesHelper } from '../../util/parties.function';

interface IAddUpdateGuestProps {
  partyId: string;
  guestId: string | null;
  onSubmit?: () => void;
}

// Memoized selectors
const memoizedGuests = createSelector(
  (state: RootState) => state.guests,
  (guest) => guest
);
const memoizedParties = createSelector(
  (state: RootState) => state.parties,
  (party) => party
);
const memoizedGuest = createSelector(
  (state: RootState, guestId: string | null) => {
    return state.guests.find((guest) => guest.id === guestId);
  },
  (guest) => guest
);
const memoizedGuestTopConstraints = createSelector(
  (state: RootState, guestId: string | null) => {
    return state.topConstraints.filter((tc) => tc.guestId === guestId);
  },
  (guest) => guest
);
const memoizedGuestConstraints = createSelector(
  (state: RootState, guestId: string | null) => {
    return state.guestConstraints.filter(
      (gc) => gc.guestId1 === guestId || gc.guestId2 === guestId
    );
  },
  (guest) => guest
);

const memoizedSpecialTops = createSelector(
  (state: RootState) => {
    return state.tops.filter((top) => top.isSpecial).sort((a, b) => a.name!.localeCompare(b.name!));
  },
  (top) => top
);

const AddUpdateGuest: React.FunctionComponent<IAddUpdateGuestProps> = (props) => {
  const isMobile = useMobile();
  const { partyId, guestId } = props;
  const dispatch = useDispatch();
  const guests = useSelector((state: RootState) => memoizedGuests(state));
  const parties = useSelector((state: RootState) => memoizedParties(state));
  const specialTops = useSelector((state: RootState) => memoizedSpecialTops(state));
  const guest = useSelector((state: RootState) => memoizedGuest(state, guestId));
  const topConstraints = useSelector((state: RootState) =>
    memoizedGuestTopConstraints(state, guestId)
  );
  const guestConstraints = useSelector((state: RootState) =>
    memoizedGuestConstraints(state, guestId)
  );

  const validateTableSelection = (value: undefined | null | string) => {
    if (__.IsNullOrUndefinedOrEmpty(value)) {
      return 'Invalid table selection';
    }
    return true; // Return true if the value is valid
  };
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, touchedFields },
    setValue,
  } = useForm();

  const [isSpecial, setIsSpecial] = React.useState(false);

  React.useEffect(() => {
    if (guestId && guest !== undefined && guest !== null) {
      // we need to ensure that all fields are patched
      // otherwise, some fields might not be set afterwards
      Object.keys(guest).forEach((key: string) => {
        setValue(key, guest[key as keyof Guest]);
      });
      setIsSpecial(guest?.isSpecial || false);
    }
  }, [guestId, guest, setValue]);

  const onSubmit = (values: any) => {
    // ensuring it is set properly
    values.isSpecial = isSpecial;
    if (__.IsNullOrUndefinedOrEmpty(values.name)) {
      return;
    }

    if (guest?.isSpecial && !values.isSpecial) {
      dispatch(topConstraintsActions.deleteForGuest(guestId!));
    }

    const topId = values.table;
    delete values.table;

    const newPartyId = values.partyId;
    const guestsByPartyId = GuestsHelper.generateGuestsByPartyId(guests);
    const partyGuests = __.IsNullOrUndefinedOrEmpty(guestsByPartyId[partyId])
      ? []
      : guestsByPartyId[partyId];

    let guestIdToUse;

    const updateParty = !__.IsNullOrUndefinedOrEmpty(newPartyId) && newPartyId !== partyId;
    if (guestId) {
      guestIdToUse = guestId;
      dispatch(
        guestsActions.update({
          ...values,
          id: guestId,
          partyId: updateParty ? newPartyId : partyId,
        })
      );

      const index = partyGuests.findIndex((g) => g.id === guestId);

      // if party was updated, we remove the guest from the party and add to new one, otherwise we just need to make sure that the name works
      if (updateParty) {
        partyGuests.splice(index, 1);
      } else {
        partyGuests[index] = {
          ...partyGuests[index],
          ...values,
        };
      }
    } else {
      guestIdToUse = uuidv4();
      const guest = {
        ...values,
        id: guestIdToUse,
        partyId: partyId,
      };
      dispatch(guestsActions.add(guest));
      partyGuests.push(guest);
    }

    // updating the original partyId
    dispatch(
      partiesActions.updateCommputedName({
        id: partyId,
        computedName: PartiesHelper.joinCenterpieceStyle(partyGuests),
        displayName: '',
      })
    );

    // and also updating the new party if it changed
    if (updateParty) {
      const newPartyGuests = guestsByPartyId[newPartyId];
      newPartyGuests.push(values);
      dispatch(
        partiesActions.updateCommputedName({
          id: newPartyId,
          computedName: PartiesHelper.joinCenterpieceStyle(newPartyGuests),
          displayName: '',
        })
      );
    }

    // if the guest is a special guest and does not have a topConstraint yet, we will set it
    if (
      values.isSpecial &&
      __.IsNullOrUndefinedOrEmpty(topConstraints) &&
      !__.IsNullOrUndefinedOrEmpty(topId)
    ) {
      dispatch(
        topConstraintsActions.add({
          guestId: guestIdToUse,
          topId,
        })
      );
    }

    // if the guest is special and has any guest constraints, we can delete them
    if (values.isSpecial && !__.IsNullOrUndefinedOrEmpty(guestConstraints)) {
      dispatch(guestConstraintsActions.deleteForGuest(guestIdToUse));
    }

    if (props.onSubmit) {
      props.onSubmit();
    }
  };

  return (
    <ModalContent marginX={isMobile ? 4 : 0}>
      <ModalHeader>{guestId ? 'Edit Guest' : 'Add Guest'}</ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <FormControl isInvalid={touchedFields.name && errors.name}>
            <FormLabel>
              Name <span style={{ color: 'red' }}>*</span>
            </FormLabel>
            <Input
              id='name'
              data-1p-ignore
              {...register('name', {
                required: 'Name is required',
                minLength: 2,
                maxLength: 127,
              })}
            />
            {errors.name && <FormErrorMessage>A name is required</FormErrorMessage>}
          </FormControl>

          <FormControl isInvalid={touchedFields.partyId && errors.partyId}>
            <FormLabel>
              Party <span style={{ color: 'red' }}>*</span>
            </FormLabel>
            <Select
              id='partyId'
              disabled={parties.length === 0}
              {...register('partyId')}
              defaultValue={partyId}
            >
              {parties.map((party) => (
                <option value={party.id} key={party.id}>
                  {PartiesHelper.getName(party)}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl display='flex' alignItems='center' style={{ marginTop: '25px' }}>
            <FormLabel htmlFor='isSpecial' marginBottom='0'>
              Is Special Guest
            </FormLabel>
            <Switch
              id='isSpecial'
              onChange={(event) => setIsSpecial(event.target.checked)}
              defaultChecked={__.IsNullOrUndefined(guest) || !guest!.isSpecial ? false : true}
            />
          </FormControl>

          {isSpecial && __.IsNullOrUndefinedOrEmpty(topConstraints) && (
            <>
              <Alert status='info' marginTop={4}>
                <AlertIcon />A special guest (e.g. bridesmaid, child) must be seated at a special
                table (e.g. Head Table, Kids Table) and will not be considered by the seating
                algorithm.
              </Alert>
              <FormControl
                style={{ marginTop: '10px' }}
                isInvalid={!__.IsNullOrUndefinedOrEmpty(errors.table)}
              >
                <FormLabel>
                  Table <span style={{ color: 'red' }}>*</span>
                </FormLabel>
                <Select
                  id='table'
                  {...register('table', {
                    validate: validateTableSelection,
                  })}
                  disabled={__.IsNullOrUndefinedOrEmpty(specialTops)}
                >
                  {specialTops.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>Table must be assigned for special guest</FormErrorMessage>
              </FormControl>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            marginTop={4}
            color='black'
            background='pastelMint'
            variant='solid'
            isLoading={isSubmitting}
            type='submit'
          >
            {guestId ? 'Save Changes' : 'Add Guest'}
          </Button>
        </ModalFooter>
      </form>
    </ModalContent>
  );
};

export default AddUpdateGuest;
