import { StylesConfig } from 'react-select';

// Define the function to generate custom styles
export const getCustomStyles = (
  colorMode: 'light' | 'dark'
): StylesConfig<{ value: string; label: string }, false> => ({
  control: (base, state) => ({
    ...base,
    backgroundColor: colorMode === 'dark' ? '#2D3748' : '#EDF2F7',
    borderColor: state.isFocused ? '#3182CE' : colorMode === 'dark' ? '#4A5568' : '#CBD5E0',
    boxShadow: state.isFocused ? '0 0 0 1px #3182CE' : 'none',
    '&:hover': {
      borderColor: state.isFocused ? '#3182CE' : colorMode === 'dark' ? '#4A5568' : '#CBD5E0',
    },
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? colorMode === 'dark'
        ? '#4A5568'
        : '#BEE3F8'
      : state.isFocused
      ? colorMode === 'dark'
        ? '#2D3748'
        : '#E2E8F0'
      : 'transparent',
    color: colorMode === 'dark' ? 'white' : 'black',
    '&:hover': {
      backgroundColor: colorMode === 'dark' ? '#2C5282' : '#90CDF4',
    },
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: colorMode === 'dark' ? '#2D3748' : 'white',
  }),
  singleValue: (base) => ({
    ...base,
    color: colorMode === 'dark' ? 'white' : 'black',
  }),
  placeholder: (base) => ({
    ...base,
    color: colorMode === 'dark' ? '#A0AEC0' : '#718096',
  }),
  input: (base) => ({
    ...base,
    color: colorMode === 'dark' ? 'white' : 'black', // Ensures input text is white in dark mode
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: colorMode === 'dark' ? '#A0AEC0' : '#718096',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    backgroundColor: colorMode === 'dark' ? '#A0AEC0' : '#CBD5E0',
  }),
});
