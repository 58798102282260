import * as React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Error from './pages/error';
import Guests from './pages/guests';
import Home from './pages/home';
import Plan from './pages/plan';
import Tables from './pages/tops';
import About from './pages/about';

const router = createBrowserRouter([
  {
    path: '/guests',
    element: <Guests />,
    errorElement: <Error />,
  },
  {
    path: '/tables',
    element: <Tables />,
    errorElement: <Error />,
  },
  {
    path: '/plan',
    element: <Plan />,
    errorElement: <Error />,
  },
  {
    path: '/about',
    element: <About />,
    errorElement: <Error />,
  },
  {
    path: '/',
    element: <Home />,
    errorElement: <Error />,
  },
]);

const Router: React.FunctionComponent = () => {
  return <RouterProvider router={router} />;
};

export default Router;
