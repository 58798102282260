import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Text,
  Link,
  CloseButton,
  Flex,
  HStack,
  useColorMode,
  useColorModeValue,
  ButtonGroup,
} from '@chakra-ui/react';

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(true);
  const { colorMode } = useColorMode();
  const cookiesAcceptedKey = 'cookiesAccepted';
  const trueValue = 'true';
  const falseValue = 'false';

  const bannerBackgroundColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('black', 'white');

  const handleAcceptCookies = () => {
    localStorage.setItem(cookiesAcceptedKey, trueValue);
    setShowBanner(false);
  };

  const handleDeclineCookies = () => {
    localStorage.setItem(cookiesAcceptedKey, falseValue);
    setShowBanner(false);
  };

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem(cookiesAcceptedKey);
    if (cookiesAccepted === trueValue) {
      setShowBanner(false);
    }
  }, []);

  if (!showBanner) {
    return null;
  }

  return (
    <Box
      background={bannerBackgroundColor}
      padding={4}
      pos='fixed'
      bottom={0}
      left={0}
      width='100%'
      zIndex={9999}
      border={colorMode === 'dark' ? 'none' : '1px solid black'}
    >
      <CloseButton pos='absolute' top={1} right={1} onClick={() => setShowBanner(false)} />
      <Flex justify='center'>
        <HStack spacing={-2}>
          <Text color={textColor} marginRight={4}>
            This website uses cookies to improve your experience.
          </Text>
          <Link color='teal.500' href='/cookie-policy.html' target='_blank' isExternal>
            Learn More
          </Link>
        </HStack>
      </Flex>
      <Flex marginTop={2} justify='center'>
        <ButtonGroup>
          <Button
            color='black'
            background='pastelMint'
            variant='solid'
            size='sm'
            onClick={handleAcceptCookies}
          >
            Accept Cookies
          </Button>
          <Button
            color='black'
            background='pastelRed'
            variant='solid'
            size='sm'
            onClick={handleDeclineCookies}
          >
            Decline Cookies
          </Button>
        </ButtonGroup>
      </Flex>
    </Box>
  );
};

export default CookieBanner;
