import * as React from 'react';
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Stack,
  StackDivider,
  useColorMode,
} from '@chakra-ui/react';
import { Guest, Top } from 'centerpiece-algorithm-client';
import { StarIcon } from '@chakra-ui/icons';
import { GuestsHelper } from '../../util/guests.function';

interface ITopAssignmentProps {
  top: Top;
  guests: Guest[];
}

const TopAssignments: React.FunctionComponent<ITopAssignmentProps> = (props) => {
  const { top, guests } = props;
  const { colorMode } = useColorMode();

  return (
    <>
      <Card variant={colorMode === 'light' ? 'outline' : 'elevated'} className='print-card'>
        <CardHeader className='print-card-header'>
          <Stack spacing={3} direction='row' justify='space-between' align='center'>
            <Stack direction='row' align='center'>
              <IconButton
                aria-label='This does nothing'
                variant='outline'
                size={'xs'}
                icon={<StarIcon />}
                color={top.isSpecial ? 'pastelMint' : 'pastelRed'}
              />
              <Heading className='print-heading' maxWidth={[230]} size='md'>
                {top.name}
              </Heading>
            </Stack>

            {/* Capacity Display */}
            <Box fontSize='sm' color='gray.500'>
              {`${guests.length}/${top.maxCapacity}`}
            </Box>
          </Stack>
        </CardHeader>

        <CardBody className='print-card-body'>
          <Stack divider={<StackDivider />} spacing='4'>
            <Box>
              {guests
                .map((guest) => guest)
                .sort((a, b) => GuestsHelper.compare(a, b, true))
                .map((guest) => (
                  <Grid
                    templateColumns='repeat(2, 1fr)'
                    gap={6}
                    width='100%'
                    borderBottomWidth={1}
                    padding={1}
                    key={guest.id}
                    className='print-grid'
                  >
                    <GridItem width='100%' display='flex' alignItems='center'>
                      <Box
                        maxWidth={[300, 250, 300]}
                        whiteSpace='nowrap'
                        overflow='hidden'
                        textOverflow='ellipsis'
                      >
                        {`${guest.name}`}
                      </Box>
                    </GridItem>
                  </Grid>
                ))}
            </Box>
          </Stack>
        </CardBody>
        <div className='print-watermark' style={{ display: 'none' }}>
          By SeatingQuest
        </div>
      </Card>
    </>
  );
};

export default TopAssignments;
