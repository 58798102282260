import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormLabel,
  Input,
  Link,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { parse } from 'papaparse';
import { Guest, Top, TopShapeEnum } from 'centerpiece-algorithm-client';
import { v4 as uuidv4 } from 'uuid';
import { IParty } from '../types/party';
import { GuestsHelper } from '../util/guests.function';
import { guestConstraintsActions } from '../store/slices/guest-constraints-slice';
import { guestsActions } from '../store/slices/guests-slice';
import { partiesActions } from '../store/slices/parties-slice';
import { resultActions } from '../store/slices/result-slice';
import { topsActions } from '../store/slices/tops-slice';
import { topConstraintsActions } from '../store/slices/top-constraints-slice';
import { PartiesHelper } from '../util/parties.function';
import useMobile from '../hooks/useMobile';
import { __ } from '../util/object.function';
import { RootState } from '../store/store';

const MAX_FILE_SIZE = 1024 * 1024; // 1 MB

interface ISetupUploadDataProps {
  onSubmit?: () => void;
}

enum UploadType {
  DATA_FILE = 'DATA_FILE',
  GUESTS_FILE = 'GUESTS_FILE',
}

const UploadData: React.FunctionComponent<ISetupUploadDataProps> = (props) => {
  const [uploadType, setUploadType] = React.useState<UploadType | null>(null);
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [error, setError] = React.useState<string | null>(null);
  const tops = useSelector((state: RootState) => state.tops);
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm();

  const handleDataFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      // Check file type (must be JSON)
      if (file.type !== 'application/json') {
        setError('Invalid file type. Please upload a JSON file.');
        console.error(error);
        return;
      }
      // Check file size
      if (file.size > MAX_FILE_SIZE) {
        setError('File size exceeds the maximum limit (1 MB).');
        console.error(error);
        return;
      }
      setSelectedFile(file);
      setError(null);
      setUploadType(UploadType.DATA_FILE);
    }
  };

  const handleGuestsFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      // Check file type (must be CSV)
      if (file.type !== 'text/csv') {
        setError('Invalid file type. Please upload a CSV file from TheKnot.');
        console.error(error);
        return;
      }
      // Check file size
      if (file.size > MAX_FILE_SIZE) {
        setError('File size exceeds the maximum limit (1 MB).');
        console.error(error);
        return;
      }
      setSelectedFile(file);
      setError(null);
      setUploadType(UploadType.GUESTS_FILE);
    }
  };

  /**
   * Indicates if the row has the following properties 'Last name', 'First name' and Party
   * @param row
   * @returns
   */
  const isValidRow = (row: any) => {
    return (
      !__.IsNullOrUndefinedOrEmpty(row['Last Name']) ||
      !__.IsNullOrUndefinedOrEmpty(row['First Name'])
    );
  };

  const generateExampleCSV = () => {
    let csvContent = 'Last Name,First Name,Party\n';
    const exampleRecords = [
      { lastName: 'Potter', firstName: 'Harry', party: "Dumbledore's Army" },
      { lastName: 'Weasley', firstName: 'Ron', party: "Dumbledore's Army" },
      { lastName: 'Granger', firstName: 'Hermione', party: "Dumbledore's Army" },
      { lastName: 'Longbottom', firstName: 'Neville', party: "Dumbledore's Army" },
      { lastName: 'Dumbledore', firstName: 'Albus', party: 'Order of the Phoenix' },
      { lastName: 'Black', firstName: 'Sirius', party: 'Order of the Phoenix' },
      { lastName: 'Lupin', firstName: 'Remus', party: 'Order of the Phoenix' },
      { lastName: 'McGonagall', firstName: 'Minerva', party: 'Order of the Phoenix' },
      { lastName: 'Hagrid', firstName: 'Rubeus', party: 'Order of the Phoenix' },
      { lastName: 'Malfoy', firstName: 'Lucius', party: 'Deatheater' },
      { lastName: 'Malfoy', firstName: 'Draco', party: 'Deatheater' },
      { lastName: 'Voldemort', firstName: 'Lord', party: 'Deatheater' },
      { lastName: 'Lestrange', firstName: 'Bellatrix', party: 'Deatheater' },
    ];

    exampleRecords.forEach((record) => {
      csvContent += `${record.lastName},${record.firstName},${record.party}\n`;
    });

    return csvContent;
  };

  const handleDownloadExampleFile = () => {
    const csvContent = generateExampleCSV();
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    const localTimestamp = new Date().toISOString().replace(/[-:]/g, '').slice(0, -5);
    a.download = `seating-quest-${localTimestamp}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const onSubmit = async (_: any) => {
    if (selectedFile) {
      if (uploadType === UploadType.DATA_FILE) {
        const reader = new FileReader();
        reader.onload = (event) => {
          try {
            const uploadedData = JSON.parse(event.target?.result as string);
            // Perform additional content validation here
            // For example, check if the JSON data matches an expected schema
            // Sanitize the JSON data (remove any potentially harmful content)
            // Dispatch actions to replace current state with the uploaded data
            dispatch(guestsActions.replace(uploadedData.guests));
            dispatch(topsActions.replace(uploadedData.tops));
            dispatch(partiesActions.replace(uploadedData.parties));
            dispatch(guestConstraintsActions.replace(uploadedData.guestConstraints));
            dispatch(topConstraintsActions.replace(uploadedData.topConstraints));
            dispatch(resultActions.replace(uploadedData.result));
          } catch (error) {
            setError('Error parsing uploaded data.');
          }
        };
        reader.readAsText(selectedFile);
      } else if (uploadType === UploadType.GUESTS_FILE) {
        const reader = new FileReader();
        reader.onload = async ({ target }) => {
          const csv = parse((target as any).result, {
            header: true,
          });
          const parsedData: any = csv?.data.filter((row) => isValidRow(row));
          const guestsWithoutParty = parsedData.filter((row: any) =>
            __.IsNullOrUndefinedOrEmpty(row['Party'])
          );

          const partyPlaceholder = 'PARTY_PLACEHOLDER';

          for (const guestWithoutParty of guestsWithoutParty) {
            guestWithoutParty['Party'] = `${partyPlaceholder}_${uuidv4()}`;
          }

          const parties: IParty[] = Array.from(
            new Set(parsedData.map((row: any) => row['Party']) as string)
          ).map((party) => ({
            displayName: party,
            computedName: '',
            id: uuidv4(),
          }));

          const guests: Array<Guest> = parsedData.map((row: any) => {
            return {
              id: uuidv4(),
              name: `${row['First Name']} ${row['Last Name']}`,
              firstName: row['First Name'],
              lastName: row['Last Name'],
              partyId: parties.find((party) => party.displayName === row['Party'])?.id,
              age: 20,
              isSpecial: false,
              role: 'Guest',
            };
          });

          const guestsByPartyId = GuestsHelper.generateGuestsByPartyId(guests);
          parties.forEach((party) => {
            const partyGuests = guestsByPartyId[party.id];
            // if it is a placeholder party name, it can be cleared
            if (party.displayName.includes(partyPlaceholder)) {
              party.displayName = '';
            }
            party.computedName = PartiesHelper.joinCenterpieceStyle(partyGuests);
            const partyGuestsInDisplayName = partyGuests.filter(
              (g) =>
                (!__.IsNullOrUndefinedOrEmpty(g.firstName) &&
                  party.displayName.includes(g.firstName!)) ||
                party.displayName.includes(g.name)
            );
            // if all names are in the displayName, we will use the computed one
            if (partyGuestsInDisplayName.length === partyGuests.length) {
              party.displayName = '';
            }
          });
          dispatch(guestConstraintsActions.clear());
          dispatch(topConstraintsActions.clear());
          dispatch(resultActions.clear());
          dispatch(partiesActions.replace(parties));
          dispatch(guestsActions.replace(guests));
          if (__.IsNullOrUndefinedOrEmpty(tops) && !__.IsNullOrUndefinedOrEmpty(guests)) {
            const topCapacity = 10;
            const topsCount = Math.ceil(guests.length / topCapacity);
            const tops: Top[] = [];
            for (let index = 0; index < topsCount; index++) {
              tops.push({
                id: uuidv4(),
                maxCapacity: topCapacity,
                shape: TopShapeEnum.circle,
                isSpecial: false,
                name: `Table ${index + 1}`,
              });
            }
            dispatch(topsActions.addMultiple(tops));
          }
        };
        reader.readAsText(selectedFile);
      }
    }
    if (props.onSubmit) {
      props.onSubmit();
    }
  };

  return (
    <ModalContent mx={isMobile ? 4 : 0}>
      <ModalHeader>Import Data From File</ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <FormControl>
            <FormLabel>
              TheKnot Guests File{' '}
              <Link href='#' color='teal.500' onClick={handleDownloadExampleFile}>
                (Example File)
              </Link>
            </FormLabel>
            <Input
              id='guests'
              type='file'
              accept='.csv'
              {...register('guestFile')}
              onChange={handleGuestsFileChange}
              style={{ paddingTop: '4px' }}
            />
          </FormControl>
          <FormControl>
            <FormLabel marginTop={8}>SeatingQuest Data File</FormLabel>
            <Input
              id='allData'
              type='file'
              accept='.json'
              {...register('dataFile')}
              onChange={handleDataFileChange}
              style={{ paddingTop: '4px' }}
            />
          </FormControl>
          <Alert status='info' marginTop={4}>
            <AlertIcon />
            This will delete all your guests and constraints
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button color='black' background='pastelMint' isLoading={isSubmitting} type='submit'>
            Continue
          </Button>
        </ModalFooter>
      </form>
    </ModalContent>
  );
};

export default UploadData;
