import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Top as ITop } from 'centerpiece-algorithm-client';

const initialState: Array<ITop> = [];
export const topsSlice = createSlice({
  name: 'tops',
  initialState,
  reducers: {
    replace: (_state, action: PayloadAction<ITop[]>) => {
      return action.payload;
    },
    add: (state, action: PayloadAction<ITop>) => {
      return [action.payload, ...state];
    },
    addMultiple: (state, action: PayloadAction<ITop[]>) => {
      return [...state, ...action.payload];
    },
    delete: (state, action: PayloadAction<string>) => {
      // @ts-ignore
      return state.filter(top => top.id !== action.payload);
    },
    update: (
      state,
      action: PayloadAction<{
        id: string;
        top: Partial<ITop>;
      }>
    ) => {
      return state.map(top => {
        if (top.id === action.payload.id) {
          return {
            ...top,
            ...action.payload.top,
          };
        }
        return top;
      });
    },
    clear: () => {
      return [];
    },
  },
});

// Action creators are generated for each case reducer function
export const topsActions = topsSlice.actions;

export default topsSlice.reducer;
