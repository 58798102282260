import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  useColorMode,
} from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Guest, GuestConstraint, GuestConstraintType } from 'centerpiece-algorithm-client';
import { validate as isValidUuid } from 'uuid';
import { EnumHelper } from '../../util/enum.function';
import useMobile from '../../hooks/useMobile';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { GuestsHelper } from '../../util/guests.function';
import { useForm } from 'react-hook-form';
import { PartiesHelper } from '../../util/parties.function';
import { __ } from '../../util/object.function';
import { guestConstraintsActions } from '../../store/slices/guest-constraints-slice';
import { createSelector } from 'reselect';
import { IParty } from '../../types/party';
import Select from 'react-select'; // Import react-select
import { getCustomStyles } from '../reusable/react-select'; // Import custom styles for react-select

interface AddPreferenceModalProps {
  isOpen: boolean;
  onClose: () => void;
  guestId: string;
}

interface IFormValues {
  guestId: string;
  guestConstraintType: GuestConstraintType;
}

// Create a memoized selector using createSelector
export const allGuestConstraintsSelector = createSelector(
  [
    (state: RootState) => state.guestConstraints,
    (_: RootState, guestsOfSamePartyCurrentGuest: Set<string>) => guestsOfSamePartyCurrentGuest,
  ],
  (guestConstraints, guestsOfSamePartyCurrentGuest) =>
    guestConstraints.filter(
      (constraint: GuestConstraint) =>
        guestsOfSamePartyCurrentGuest.has(constraint.guestId1!) ||
        guestsOfSamePartyCurrentGuest.has(constraint.guestId2!)
    )
);

export const guestsSelector = createSelector(
  [(state: RootState) => state.guests],
  (guests) => guests
);

export const partiesSelector = createSelector(
  [(state: RootState) => state.parties],
  (parties) => parties
);

export const guestsByPartySelector = createSelector([guestsSelector], (guests) => {
  // Generate and return guestsByParty here
  return GuestsHelper.generateGuestIdsByPartyId(guests);
});

const AddPreferenceModal: React.FC<AddPreferenceModalProps> = ({ isOpen, onClose, guestId }) => {
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const { colorMode } = useColorMode();

  const parties = useSelector((state: RootState) => partiesSelector(state));
  const guests = useSelector((state: RootState) => guestsSelector(state));

  // current guest
  const currentGuest: Guest = guests.find((guest) => guest.id === guestId)!;
  // dictionary to get guests by partyId
  const guestsByParty = useSelector(guestsByPartySelector);
  // guests belonging to the same party as the current guest
  const guestsOfSamePartyCurrentGuest = guestsByParty[currentGuest.partyId]!;
  // guest constraints of all guests belonging to the same party as the current guest
  const allGuestConstraints = useSelector((state: RootState) =>
    allGuestConstraintsSelector(state, guestsOfSamePartyCurrentGuest)
  );

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors, isSubmitting, touchedFields },
    reset,
  } = useForm();

  // watches for current partyId and toggle for filtering by party
  const watchPartyId: string = watch('partyId');
  const watchFilterByParty: boolean = watch('filterByParty');

  // states for first party and first guest
  const [defaultPartyId, setDefaultPartyId] = useState('');
  const [defaultGuestId, setDefaultGuestId] = useState('');

  const [filteredParties, setFilteredParties] = useState<IParty[]>([]);
  const [filteredGuests, setFilteredGuests] = useState<Guest[]>([]);

  useEffect(() => {
    const possibleGuests: Guest[] = guests.filter((guest) =>
      GuestsHelper.filterByGuestConstraints(
        guest,
        '',
        allGuestConstraints,
        currentGuest,
        guestsByParty
      )
    );
    // the distinct partyIds
    const possibleGuestsPartyIds = new Set(possibleGuests.map((guest: Guest) => guest.partyId));
    setFilteredParties(
      parties
        .filter((party) => possibleGuestsPartyIds.has(party.id))
        .sort((a, b) => PartiesHelper.compare(a, b))
    );

    setFilteredGuests(
      guests
        .filter((guest) =>
          GuestsHelper.filterByGuestConstraints(
            guest,
            watchFilterByParty ? watchPartyId : '',
            allGuestConstraints,
            currentGuest,
            guestsByParty
          )
        )
        .sort((a, b) => GuestsHelper.compare(a, b))
    );
  }, [
    allGuestConstraints,
    currentGuest,
    guests,
    guestsByParty,
    parties,
    watchFilterByParty,
    watchPartyId,
  ]);

  useEffect(() => {
    if (
      watchFilterByParty &&
      parties.length > 0 &&
      (__.IsNullOrUndefinedOrEmpty(defaultPartyId) || __.IsNullOrUndefinedOrEmpty(defaultGuestId))
    ) {
      // determine all possible guests to have a constraint with / to
      const possibleGuests: Guest[] = guests.filter((guest) =>
        GuestsHelper.filterByGuestConstraints(
          guest,
          '',
          allGuestConstraints,
          currentGuest,
          guestsByParty
        )
      );

      // the distinct partyIds
      const possibleGuestsPartyIds = new Set(possibleGuests.map((guest: Guest) => guest.partyId));
      const partyId = parties
        .filter((p) => possibleGuestsPartyIds.has(p.id))
        .sort((a, b) => PartiesHelper.compare(a, b))[0].id;

      const guestId = __.IsNullOrUndefinedOrEmpty(possibleGuests)
        ? ''
        : possibleGuests
            .filter((guest) => guest.partyId === partyId)
            .sort((a, b) => GuestsHelper.compare(a, b))[0].id;
      setDefaultPartyId(partyId);
      setDefaultGuestId(guestId);
    }
  }, [
    allGuestConstraints,
    currentGuest,
    defaultGuestId,
    defaultPartyId,
    guests,
    guestsByParty,
    parties,
    watchFilterByParty,
  ]);

  useEffect(() => {
    if (
      watchFilterByParty &&
      !__.IsNullOrUndefinedOrEmpty(watchPartyId) &&
      filteredGuests.length > 0
    ) {
      setDefaultGuestId(filteredGuests[0].id);
      setValue('guestId', filteredGuests[0].id); // Update the guestId value using setValue
    }
  }, [filteredGuests, setValue, watchFilterByParty, watchPartyId]);

  const onSubmit = (values: any) => {
    if (isValidUuid(values.guestId)) {
      dispatch(
        guestConstraintsActions.add({
          guestId1: (values as IFormValues).guestId,
          guestId2: guestId,
          guestConstraintType: (values as IFormValues).guestConstraintType,
        })
      );

      reset();
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent mx={isMobile ? 4 : 0}>
        <ModalHeader>Add Preference</ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <FormControl display='flex' alignItems='center'>
              <FormLabel>Filter by party</FormLabel>
              <Switch id='filter-by-party' {...register('filterByParty')} />
            </FormControl>
            <FormControl
              isInvalid={touchedFields.guestConstraintType && errors.guestConstraintType}
            >
              <FormLabel>
                Preference Type <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <Select
                styles={getCustomStyles(colorMode)}
                options={[
                  {
                    value: GuestConstraintType.must,
                    label: EnumHelper.GetConstraintTypeDisplayName(GuestConstraintType.must),
                  },
                  {
                    value: GuestConstraintType.should,
                    label: EnumHelper.GetConstraintTypeDisplayName(GuestConstraintType.should),
                  },
                  {
                    value: GuestConstraintType.shouldNot,
                    label: EnumHelper.GetConstraintTypeDisplayName(GuestConstraintType.shouldNot),
                  },
                  {
                    value: GuestConstraintType.mustNot,
                    label: EnumHelper.GetConstraintTypeDisplayName(GuestConstraintType.mustNot),
                  },
                ]}
                onChange={(option) => setValue('guestConstraintType', option?.value)}
                placeholder='Select preference type'
                isSearchable
              />
            </FormControl>
            {watchFilterByParty && (
              <FormControl isInvalid={touchedFields.partyId && errors.partyId}>
                <FormLabel>
                  Party <span style={{ color: 'red' }}>*</span>
                </FormLabel>
                <Select
                  styles={getCustomStyles(colorMode)}
                  options={filteredParties.map((party) => ({
                    value: party.id,
                    label: PartiesHelper.getName(party),
                  }))}
                  onChange={(option) => setValue('partyId', option?.value)}
                  placeholder='Search party...'
                  defaultValue={
                    defaultPartyId
                      ? { value: defaultPartyId, label: PartiesHelper.getName(filteredParties[0]) }
                      : null
                  }
                  isSearchable
                />
              </FormControl>
            )}
            <FormControl isInvalid={touchedFields.guestId && errors.guestId}>
              <FormLabel>
                Guest <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <Select
                styles={getCustomStyles(colorMode)}
                options={filteredGuests.map((guest) => ({
                  value: guest.id,
                  label: guest.name,
                }))}
                onChange={(option) => setValue('guestId', option?.value)}
                placeholder='Search guest...'
                defaultValue={
                  defaultGuestId ? { value: defaultGuestId, label: filteredGuests[0].name } : null
                }
                isSearchable
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              marginTop={4}
              color='black'
              background='pastelMint'
              isLoading={isSubmitting}
              type='submit'
            >
              Add Preference
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default AddPreferenceModal;
