import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormLabel,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';
import { Guest, PlanRequest, WeddingDataRequest } from 'centerpiece-algorithm-client';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { guestConstraintsActions } from '../../store/slices/guest-constraints-slice';
import { guestsActions } from '../../store/slices/guests-slice';
import { partiesActions } from '../../store/slices/parties-slice';
import { topConstraintsActions } from '../../store/slices/top-constraints-slice';
import { topsActions } from '../../store/slices/tops-slice';
import { IParty } from '../../types/party';
import { api } from '../../util/api';
import { v4 as uuidv4 } from 'uuid';
import { PartiesHelper } from '../../util/parties.function';
import useMobile from '../../hooks/useMobile';
import { resultActions } from '../../store/slices/result-slice';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

interface IAddGuestProps {
  onSubmit?: () => void;
}

const GenerateData: React.FunctionComponent<IAddGuestProps> = (props) => {
  const isMobile = useMobile();
  const dispatch = useDispatch();

  // Define validation schema using yup
  const validationSchema = yup.object().shape({
    numGuests: yup
      .number()
      .typeError('Please enter a valid number')
      .integer('Please enter a valid number')
      .min(2, 'Please choose a number between 2 and 100')
      .max(100, 'Please choose a number between 2 and 100')
      .required('Number of guests is required'),
    dataKey: yup.string().uuid(),
  });

  const {
    handleSubmit,
    register,
    trigger,
    formState: { errors, isSubmitting, touchedFields },
    setValue,
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      dataKey: uuidv4(),
      numGuests: 50,
    },
    resolver: yupResolver(validationSchema),
  });

  const generateDataUntilValid = async (request: WeddingDataRequest) => {
    let response;
    let validationStatus = 'INVALID';
    let counter = 0;
    const maxAttempts = 20;
    do {
      counter++;
      response = await api.postGenerateWeddingData(request);
      const validationRequest: PlanRequest = {
        dataKey: uuidv4(),
        guestConstraints: response.data.guestConstraints,
        topConstraints: response.data.topConstraints,
        guests: response.data.guests,
        tops: response.data.tops,
        planConfig: {},
      };
      const validationResponse = await api.postValidateInputs(validationRequest);
      validationStatus = validationResponse.data.validationStatus;
    } while (validationStatus !== 'VALID' && counter <= maxAttempts);
    if (counter === maxAttempts) {
      console.error('Could not genenerate valid data');
    }
    return response;
  };

  const handleNumGuestsChange = (value: number) => {
    const currentNumGuests = Number(getValues('numGuests'));
    const updatedNumGuests = currentNumGuests + value;
    setValue('numGuests', updatedNumGuests);
    trigger('numGuests');
  };

  const onSubmit = async (request: WeddingDataRequest) => {
    dispatch(partiesActions.clear());
    dispatch(topsActions.clear());
    dispatch(guestsActions.clear());
    dispatch(guestConstraintsActions.clear());
    dispatch(topConstraintsActions.clear());
    dispatch(resultActions.clear());
    try {
      const response = await generateDataUntilValid(request);
      // group guests by partyId
      const guestsByPartyId: { [partyId: string]: Guest[] } = response.data.guests.reduce(
        (result: { [partyId: string]: Guest[] }, guest: Guest) => {
          if (!result[guest.partyId]) {
            result[guest.partyId] = [];
          }
          result[guest.partyId].push(guest);
          return result;
        },
        {}
      );
      // generate parties
      const parties: IParty[] = Object.keys(guestsByPartyId).map((partyId) => {
        return {
          id: partyId,
          // name should be the name of all guests belonging to this party concatenated by an &
          computedName: PartiesHelper.joinCenterpieceStyle(guestsByPartyId[partyId]),
          displayName: '',
        };
      });
      dispatch(guestsActions.addMultiple(response.data.guests));
      dispatch(partiesActions.addMultiple(parties));
      dispatch(topsActions.addMultiple(response.data.tops));
      dispatch(guestConstraintsActions.addMultiple(response.data.guestConstraints));
      dispatch(topConstraintsActions.addMultiple(response.data.topConstraints));
    } catch (err: any) {
      console.error(err);
    }
    if (props.onSubmit) {
      props.onSubmit();
    }
  };

  return (
    <ModalContent mx={isMobile ? 4 : 0}>
      <ModalHeader>Generate data</ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <FormControl isInvalid={!!(touchedFields.numGuests && errors.numGuests)}>
            <FormLabel>Number of Guests</FormLabel>
            <NumberInput>
              <NumberInputField {...register('numGuests')} />
              <NumberInputStepper>
                <NumberIncrementStepper onClick={() => handleNumGuestsChange(+1)} />
                <NumberDecrementStepper onClick={() => handleNumGuestsChange(-1)} />
              </NumberInputStepper>
            </NumberInput>
            {errors.numGuests && (
              <Alert status='error' marginTop={2}>
                <AlertIcon />
                {errors.numGuests.message}
              </Alert>
            )}
          </FormControl>
          <Alert status='info' marginTop={4}>
            <AlertIcon />
            This will delete all your guests, tables and constraints
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button
            marginTop={4}
            color='black'
            background='pastelMint'
            isLoading={isSubmitting}
            type='submit'
            isDisabled={!!errors.numGuests} // Disable the button if there are errors
          >
            Generate
          </Button>
        </ModalFooter>
      </form>
    </ModalContent>
  );
};

export default GenerateData;
