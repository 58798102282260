import { Editable, EditableInput, EditablePreview, Heading } from '@chakra-ui/react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { RootState } from '../../store/store';
import { partiesActions } from '../../store/slices/parties-slice';
import EditableControls from '../reusable/editable/editable-controls';
import { __ } from '../../util/object.function';
import { PartiesHelper } from '../../util/parties.function';

interface IPartyNameProps {
  partyId: string;
}

// Create a selector function to get the raw state.parties
const rawPartiesSelector = (state: RootState) => state.parties;

// Create a memoized selector using createSelector
export const partyNameSelector = createSelector(
  [rawPartiesSelector, (state: RootState, partyId: string) => partyId],
  (parties, partyId) => {
    const party = parties.find((party) => party.id === partyId);
    if (party) {
      return PartiesHelper.getName(party);
    }
    return undefined; // Return undefined if party with the given id is not found
  }
);

const PartyName: React.FunctionComponent<IPartyNameProps> = (props) => {
  const { partyId } = props;
  // subscribing to party changes
  const partyName = useSelector((state: RootState) => partyNameSelector(state, partyId));
  const computedName = useSelector((state: RootState) =>
    state.parties.find((party) => party.id === partyId)
  )?.computedName;
  const dispatch = useDispatch();

  const [value, setValue] = React.useState(partyName);

  React.useEffect(() => {
    setValue(partyName);
  }, [partyName]);

  return (
    <Editable
      display='flex'
      alignItems='center'
      width={[220, 380, 500, 260]}
      gap={1}
      value={value}
      onChange={(nextValue) => setValue(nextValue)}
      onSubmit={(nextValue) => {
        // if the nextValue is an empty string and we were
        // clearing the computed value, no update will be triggered
        if (__.IsNullOrUndefinedOrEmpty(nextValue)) {
          setValue(computedName);
        }
        dispatch(
          partiesActions.updateDisplayName({
            id: partyId,
            displayName: nextValue,
            computedName: '',
          })
        );
      }}
    >
      <Heading
        as={EditablePreview}
        // as this is a children, we will define the maxWidth to only grow if needed
        maxWidth={[220, 380, 500, 260]}
        size='md'
      />
      <EditableInput px='4' maxLength={64} />
      <EditableControls tooltipLabel='Edit Party Name' />
    </Editable>
  );
};

export default PartyName;
