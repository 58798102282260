import { Top, TopConstraint } from 'centerpiece-algorithm-client';

export const TopsHelper = {
  /**
   * Generates a dictionary of guests by topId.
   * @param topConstraints
   * @returns
   */
  generateDictionaryByTopId(topConstraints: TopConstraint[]): { [topId: string]: Set<string> } {
    const guestsByTopId: Record<string, Set<string>> = topConstraints.reduce(
      (dictionary, topConstraint) => {
        const { topId } = topConstraint;
        if (!dictionary[topId!]) {
          dictionary[topId!] = new Set();
        }
        dictionary[topId!].add(topConstraint.guestId!);
        return dictionary;
      },
      {} as Record<string, Set<string>>
    );
    return guestsByTopId;
  },

  /**
   * Compares the name of two tops and considers that Table 3 should be before Table 10
   * @param a 
   * @param b 
   * @returns 
   */
  compare(a: Top, b: Top): number {
    // Check if one of the tables is special
    if (a.isSpecial !== b.isSpecial) {
      // If only one is special, it should come first
      return a.isSpecial ? -1 : 1;
    }

    const nameA = a.name!;
    const nameB = b.name!;

    // Extract word and numeric parts from the strings
    const matchA = nameA.match(/^(\D+)(\d+)$/);
    const matchB = nameB.match(/^(\D+)(\d+)$/);

    // If both have word and numeric parts
    if (matchA && matchB) {
      const wordA = matchA[1];
      const numA = parseInt(matchA[2], 10);
      const wordB = matchB[1];
      const numB = parseInt(matchB[2], 10);

      // Compare the word parts first
      const wordComparison = wordA.localeCompare(wordB);

      if (wordComparison !== 0) {
        return wordComparison;
      }
      // If the word parts are the same, compare numerically
      return numA - numB;
    }
    // If one or both don't have word and numeric parts, use default comparison
    return nameA.localeCompare(nameB);
  }
}
