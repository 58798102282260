import {
  Button,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import useMobile from '../../hooks/useMobile';
import { partiesActions } from '../../store/slices/parties-slice';

interface IAddGuestProps {
  onSubmit?: () => void;
}

const AddParty: React.FunctionComponent<IAddGuestProps> = (props) => {
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = (values: any) => {
    dispatch(
      partiesActions.add({
        ...values,
        id: uuidv4(),
      })
    );
    if (props.onSubmit) {
      props.onSubmit();
    }
  };

  return (
    <ModalContent mx={isMobile ? 4 : 0}>
      <ModalHeader>Add Party</ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input id='displayName' data-1p-ignore {...register('displayName', {})} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            marginTop={4}
            color='black'
            background='pastelMint'
            isLoading={isSubmitting}
            type='submit'
          >
            Add Party
          </Button>
        </ModalFooter>
      </form>
    </ModalContent>
  );
};

export default AddParty;
