import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Guest } from 'centerpiece-algorithm-client';

const initialState: Array<Guest> = [];
export const guestsSlice = createSlice({
  name: 'guests',
  initialState,
  reducers: {
    replace: (state, action: PayloadAction<Guest[]>) => {
      return action.payload;
    },
    add: (state, action: PayloadAction<Guest>) => {
      return [...state, action.payload];
    },
    addMultiple: (state, action: PayloadAction<Guest[]>) => {
      return [...state, ...action.payload];
    },
    delete: (state, action: PayloadAction<string>) => {
      return state.filter(guest => guest.id !== action.payload);
    },
    update: (state, action: PayloadAction<Guest>) => {
      return state.map(guest => {
        if (guest.id === action.payload.id) {
          return action.payload;
        }
        return guest;
      });
    },
    clear: () => {
      return [];
    },
  },
});

// Action creators are generated for each case reducer function
export const guestsActions = guestsSlice.actions;

export default guestsSlice.reducer;
