import { Guest } from 'centerpiece-algorithm-client';
import { IParty } from '../types/party';
import { __ } from './object.function';

export const PartiesHelper = {
  compare(a: IParty, b: IParty) {
    return this.getName(a).localeCompare(this.getName(b));
  },

  getName(party: IParty): string {
    return __.IsNullOrUndefinedOrEmpty(party.displayName)
      ? party.computedName?.trim()
      : party.displayName?.trim();
  },

  joinCenterpieceStyle(guests: Guest[]): string {
    switch (guests.length) {
      case 0:
        return '';
      case 1:
        return guests[0].name;
      case 2:
        return `${guests[0].name} and ${guests[1].name}`;
      default:
        const guestName = guests[0].name;
        const additional = guestName.endsWith('s') ? '' : 's';
        return `${guestName}'${additional} party`;
    }
  },
};
