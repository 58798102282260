import { Assignment, Guest, Top } from 'centerpiece-algorithm-client';
import { GuestsHelper } from './guests.function';

export const ResultHelper = {
  /**
   * Filters assignments for a top
   * @param topId
   * @param assignments
   * @returns
   */
  getAssignmentsByTopId(topId: string, assignments: Assignment[]): Set<string> {
    const topAssignments = new Set(assignments.filter(a => a.topId === topId).map(a => a.guestId));

    return topAssignments;
  },

  /**
   * Retrieves guests for a top
   * @param guests
   * @param topAssignments
   * @returns
   */
  getGuestsByTopAssignments(guests: Guest[], topAssignments: Set<string>): Guest[] {
    const topGuests = guests
      .filter(g => topAssignments.has(g.id))
      .sort((a, b) => GuestsHelper.compare(a, b));

    return topGuests;
  },

  /**
   * Retrieves guests for a top from guests and assignments
   * @param topId
   * @param guests
   * @param assignments
   * @returns
   */
  getGuestsByAssignments(topId: string, guests: Guest[], assignments: Assignment[]): Guest[] {
    const topAssignments = ResultHelper.getAssignmentsByTopId(topId, assignments);
    const topGuests = ResultHelper.getGuestsByTopAssignments(guests, topAssignments);
    return topGuests;
  },

  /**
   * Generates a guestMap where the guestId is the key
   * @param guests
   * @returns
   */
  generateGuestMap(guests: Guest[]): Map<string, Guest> {
    const guestMap: Map<string, Guest> = new Map();
    for (const guest of guests) {
      guestMap.set(guest.id, guest);
    }
    return guestMap;
  },

  /**
   * Generates a top to guest map where the topId is the key and sorts the assignments first by partyId and then by name
   * @param tops
   * @param assignments
   * @returns
   */
  generateTopGuestMap(tops: Top[], assignments: Assignment[], guestMap: Map<string, Guest>): Map<string, Array<string>> {
    const topGuestsMap: Map<string, Array<string>> = new Map();
    for (const top of tops) {
      topGuestsMap.set(top.id, []);
    }

    for (const assignment of assignments) {
      const { topId, guestId } = assignment;
      topGuestsMap.get(topId)?.push(guestId);
    }

    for (const topId of Object.keys(topGuestsMap)) {
      topGuestsMap.get(topId)?.sort((guestId1, guestId2) => GuestsHelper.compare(guestMap.get(guestId1)!, guestMap.get(guestId2)!, true))
    }

    return topGuestsMap;
  },
};
