import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { RootState } from '../../store/store';
import {
  Box,
  Button,
  ButtonGroup,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Modal,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { topConstraintsActions } from '../../store/slices/top-constraints-slice';
import { Guest } from 'centerpiece-algorithm-client';
import AddTopConstraint from './add-top-constraint';
import { GuestsHelper } from '../../util/guests.function';

interface ITopConstraintsProps {
  topId: string;
}

// Create a selector function to get the raw state.topConstraints
const rawTopConstraintsSelector = (state: RootState) => state.topConstraints;
// Create a memoized selector using createSelector
export const filteredTopConstraintsSelector = createSelector(
  [rawTopConstraintsSelector, (state: RootState, topId: string) => topId],
  (topConstraints, topId) => topConstraints.filter((constraint) => constraint.topId === topId)
);

const TopConstraints: React.FunctionComponent<ITopConstraintsProps> = (props) => {
  const { topId } = props;
  const dispatch = useDispatch();
  const top = useSelector((state: RootState) => state.tops.find((top) => top.id === topId))!;
  const guests = useSelector((state: RootState) => state.guests);
  const filteredTopConstraints = useSelector((state: RootState) =>
    filteredTopConstraintsSelector(state, topId)
  );
  const filteredGuests = filteredTopConstraints.map((constraint) =>
    guests.find((guest) => guest.id === constraint.guestId)
  ) as Guest[];
  const { isOpen: isOpenModal, onClose: onCloseModal, onOpen: onOpenModal } = useDisclosure();

  const handleDeleteClick = (guest: Guest) => {
    const guestsByParty = GuestsHelper.generateGuestIdsByPartyId(guests);
    const guestsOfSameParty = guestsByParty[guest.partyId];
    for (const id of Array.from(guestsOfSameParty)) {
      dispatch(
        topConstraintsActions.delete({
          guestId: id,
          topId,
        })
      );
    }
  };

  return (
    <>
      <FormLabel>Pre-Assigned Guests</FormLabel>
      <Grid templateColumns='repeat(2, 1fr)' gap={6} width='100%' borderBottomWidth={1} padding={1}>
        <GridItem>
          <Heading size='sm'>Name</Heading>
        </GridItem>
      </Grid>
      {filteredGuests
        .sort((a, b) => GuestsHelper.compare(a, b, true))
        .map((guest) => (
          <Grid
            templateColumns='repeat(2, 1fr)'
            gap={6}
            width='100%'
            borderBottomWidth={1}
            padding={1}
            key={guest.id}
          >
            <GridItem width='100%' display='flex' alignItems='center'>
              <Box maxWidth={[220, 330, 500]}>{`${guest.name}`}</Box>
            </GridItem>
            <GridItem width='100%' display='flex' alignItems='center' justifyContent='right'>
              <ButtonGroup>
                <IconButton
                  aria-label='Delete guest'
                  icon={<DeleteIcon />}
                  variant='solid'
                  color='black'
                  borderColor='pastelRed'
                  background='pastelRed'
                  size={'sm'}
                  onClick={() => handleDeleteClick(guest)}
                />
              </ButtonGroup>
            </GridItem>
          </Grid>
        ))}
      <Button
        leftIcon={<AddIcon />}
        onClick={onOpenModal}
        margin={2}
        variant='outline'
        isDisabled={filteredGuests.length === top.maxCapacity}
      >
        Add Guest
      </Button>
      <Modal isOpen={isOpenModal} onClose={onCloseModal}>
        <ModalOverlay />
        <AddTopConstraint topId={topId} onSubmit={onCloseModal} />
      </Modal>
    </>
  );
};

export default TopConstraints;
