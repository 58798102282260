import { Center, Text, useConst } from '@chakra-ui/react';
import * as React from 'react';

export const FOOTER_HEIGHT = 40;

const Footer: React.FunctionComponent = (props) => {
  const currentYear = useConst(new Date().getFullYear());

  return (
    <Center as='footer' h={`${FOOTER_HEIGHT}px`}>
      <Text fontSize='sm'>© SeatingQuest {currentYear}</Text>
    </Center>
  );
};

export default Footer;
