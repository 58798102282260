import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { AiOutlineLock } from 'react-icons/ai';
import { PiArrowsOutSimpleFill, PiArrowsInSimpleFill } from 'react-icons/pi';
import theme from '../../theme';
import { GuestConstraintType } from 'centerpiece-algorithm-client';

interface CustomArrowsIconProps {
  showLock: boolean;
  pointsOut: boolean;
  color: string;
}

const CustomArrowsIcon: React.FC<CustomArrowsIconProps> = ({ showLock, pointsOut, color }) => (
  <Flex
    alignItems='center'
    justifyContent='center'
    width='32px' // Adjust the size as needed
    height='32px' // Adjust the size as needed
    bg={color}
    borderRadius='50%' // Use '50%' to make it perfectly round
    position='relative'
  >
    {showLock && (
      <AiOutlineLock size={11} color='black' style={{ position: 'absolute', top: '2px' }} />
    )}
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transform: 'rotate(45deg)',
        position: 'relative',
        top: '0', // Remove the offset
      }}
    >
      {pointsOut ? (
        <PiArrowsOutSimpleFill size={20} style={{ color: 'black' }} />
      ) : (
        <PiArrowsInSimpleFill size={20} style={{ color: 'black' }} />
      )}
    </Box>
  </Flex>
);

export const getIconByGuestConstraintType = (guestConstraintType: GuestConstraintType) => {
  switch (guestConstraintType) {
    case GuestConstraintType.must:
      return <CustomArrowsIcon color={theme.colors.pastelBlue} showLock={true} pointsOut={false} />;
    case GuestConstraintType.mustNot:
      return (
        <CustomArrowsIcon color={theme.colors.pastelOrange} showLock={true} pointsOut={true} />
      );
    case GuestConstraintType.should:
      return (
        <CustomArrowsIcon color={theme.colors.pastelBlue} showLock={false} pointsOut={false} />
      );
    case GuestConstraintType.shouldNot:
      return (
        <CustomArrowsIcon color={theme.colors.pastelOrange} showLock={false} pointsOut={true} />
      );
    default:
      return '';
  }
};
