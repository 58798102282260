import {
  Alert,
  AlertIcon,
  Box,
  Card,
  Flex,
  Heading,
  IconButton,
  SimpleGrid,
  Switch,
  useColorMode,
} from '@chakra-ui/react';
import { SolverStatus, Top } from 'centerpiece-algorithm-client';
import { MdPrint } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { resultActions } from '../../store/slices/result-slice';
import { RootState } from '../../store/store';
import { ResultVisualization } from '../../types/result-visualization.enum';
import { __ } from '../../util/object.function';
import { ResultHelper } from '../../util/result.function';
import { TopsHelper } from '../../util/tops.function';
import PayPalDonateButton from '../reusable/paypal';
import TopAssignmentGraph from './top-assignment-graph';
import TopAssignments from './top-assignments';

const TopsOverview: React.FunctionComponent = () => {
  const { colorMode, setColorMode } = useColorMode();
  const tops = useSelector((state: RootState) => state.tops);

  const guests = useSelector((state: RootState) => state.guests);
  const result = useSelector((state: RootState) => state.result);
  const showTopsAssignments =
    result.solverStatus === SolverStatus.feasible || result.solverStatus === SolverStatus.optimal;

  const dispatch = useDispatch();
  const resultVisualization = useSelector((state: RootState) => state.result.resultVisualization);

  const handlePrint = () => {
    const colorModeToggled = colorMode === 'dark';

    if (colorModeToggled) {
      setTimeout(() => {
        setColorMode('light');
        setTimeout(() => {
          window.print();
        }, 1000);
      }, 500);
    } else {
      window.print();
    }

    if (colorModeToggled) {
      setTimeout(() => {
        setColorMode('dark');
      }, 2000);
    }
  };

  const toggleVisualization = () => {
    dispatch(
      resultActions.setVisualization(
        resultVisualization === ResultVisualization.CARDS
          ? ResultVisualization.GRAPH
          : ResultVisualization.CARDS
      )
    );
  };

  return (
    <>
      {!__.IsNullOrUndefined(result.solverStatus) && (
        <Heading
          className='print-hide'
          size='xs'
          style={{ justifyContent: 'flex-end', margin: '20px', display: 'flex' }}
        >
          Solver Status: {__.CapitalizeWords(result.solverStatus!)}
        </Heading>
      )}
      <Box style={{ justifyContent: 'flex-end', display: 'flex', marginBottom: '10px' }}>
        <Switch
          className='print-hide'
          size={'lg'}
          isChecked={resultVisualization === ResultVisualization.CARDS}
          onChange={toggleVisualization}
          marginRight={4}
        >
          {resultVisualization === ResultVisualization.CARDS
            ? ResultVisualization.CARDS
            : ResultVisualization.GRAPH}
        </Switch>
        {resultVisualization === ResultVisualization.CARDS && (
          <IconButton
            icon={<MdPrint />}
            className='print-hide'
            aria-label='Print'
            onClick={handlePrint}
            variant='solid'
            color='black'
            borderColor='pastelMint'
            background='pastelMint'
            size={'sm'}
          />
        )}
      </Box>
      {showTopsAssignments && resultVisualization === ResultVisualization.CARDS && (
        <SimpleGrid minChildWidth='300px' spacing='40px'>
          {tops
            .map((top: Top) => top)
            .sort((a, b) => TopsHelper.compare(a, b))
            .map((top: Top) => {
              const topGuests = ResultHelper.getGuestsByAssignments(
                top.id,
                guests,
                result.assignments
              );
              return <TopAssignments top={top} guests={topGuests} key={top.id} />;
            })}
        </SimpleGrid>
      )}
      {showTopsAssignments && resultVisualization === ResultVisualization.GRAPH && (
        <SimpleGrid minChildWidth='300px'>
          <Card variant={colorMode === 'light' ? 'outline' : 'elevated'}>
            <TopAssignmentGraph />
          </Card>
        </SimpleGrid>
      )}
      {!showTopsAssignments && !__.IsNullOrUndefined(result.solverStatus) && (
        <Alert status='warning'>
          <AlertIcon />
          Could not generate a seating plan
        </Alert>
      )}
      {showTopsAssignments && (
        <Flex justifyContent='center' marginTop='4'>
          <PayPalDonateButton />
        </Flex>
      )}
    </>
  );
};

export default TopsOverview;
