import {
  Alert,
  AlertIcon,
  Button,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { store } from '../store/store';
import useMobile from '../hooks/useMobile';

interface ISetupExportDataProps {
  onSubmit?: () => void;
}

function exportAllData(data: any, filename: string) {
  const blob = new Blob([data], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
  URL.revokeObjectURL(url);
}

const ExportData: React.FunctionComponent<ISetupExportDataProps> = (props) => {
  const isMobile = useMobile();
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = (values: any) => {
    const state = store.getState(); // Get the Redux store state
    const serializedState = JSON.stringify(state);
    const localTimestamp = new Date().toISOString().replace(/[-:]/g, '').slice(0, -5);
    const exportFilename = `seating-quest-data-${localTimestamp}.json`;
    exportAllData(serializedState, exportFilename);
    if (props.onSubmit) {
      props.onSubmit();
    }
  };

  return (
    <ModalContent mx={isMobile ? 4 : 0}>
      <ModalHeader>Export Seating Plan Data</ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Alert status='info' marginTop={4}>
            <AlertIcon />
            Clicking continue will start a download of your seating plan data!
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button
            marginTop={4}
            color='black'
            background='pastelMint'
            isLoading={isSubmitting}
            type='submit'
          >
            Continue
          </Button>
        </ModalFooter>
      </form>
    </ModalContent>
  );
};

export default ExportData;
