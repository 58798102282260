import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Switch,
  useColorMode,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { RootState } from '../../store/store';
import { topConstraintsActions } from '../../store/slices/top-constraints-slice';
import { Guest, TopConstraint } from 'centerpiece-algorithm-client';
import { validate as isValidUuid } from 'uuid';
import { __ } from '../../util/object.function';
import { PartiesHelper } from '../../util/parties.function';
import { GuestsHelper } from '../../util/guests.function';
import { IParty } from '../../types/party';
import { TopsHelper } from '../../util/tops.function';
import useMobile from '../../hooks/useMobile';
import { getCustomStyles } from '../reusable/react-select';

interface IAddTopConstraintProps {
  topId: string;
  onSubmit?: () => void;
}

const AddTopConstraint: React.FunctionComponent<IAddTopConstraintProps> = (props) => {
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const { colorMode } = useColorMode();

  // all parties and all guests
  const parties = useSelector((state: RootState) => state.parties);
  const guests = useSelector((state: RootState) => state.guests);
  const top = useSelector((state: RootState) => state.tops.find((top) => top.id === props.topId))!;
  // dictionary to get guests by partyId
  const guestsByParty = GuestsHelper.generateGuestIdsByPartyId(guests);

  // all existing top constraints
  const topConstraints = useSelector((state: RootState) => state.topConstraints);
  const guestsByTop = TopsHelper.generateDictionaryByTopId(topConstraints);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, touchedFields },
    setValue,
    watch,
  } = useForm();

  // watches for current partyId and toggle for filtering by party
  const watchPartyId = watch('partyId');
  const watchFilterByParty: boolean = watch('filterByParty');

  // states for first party
  const [defaultPartyId, setDefaultPartyId] = useState('');
  const [defaultGuestId, setDefaultGuestId] = useState('');

  // recalculating parties when any of the values change
  const filteredParties = React.useMemo(() => {
    const possibleGuests: Guest[] = guests.filter((guest) => {
      const guestsOfTop = !__.IsNullOrUndefinedOrEmpty(guestsByTop[top.id])
        ? guestsByTop[top.id]
        : new Set<string>();
      return GuestsHelper.filterByTopConstraints(
        guest,
        '',
        topConstraints,
        guestsByParty[guest.partyId]!,
        top,
        guestsOfTop
      );
    });
    const possibleGuestsPartyIds = new Set(possibleGuests.map((guest: Guest) => guest.partyId));
    return parties
      .filter((party: IParty) => possibleGuestsPartyIds.has(party.id))
      .sort((a, b) => PartiesHelper.compare(a, b));
  }, [parties, guests, topConstraints, guestsByParty, top, guestsByTop]);

  const filteredGuests = React.useMemo(() => {
    const possibleGuests = guests
      .filter((guest) => {
        const guestsOfTop = !__.IsNullOrUndefinedOrEmpty(guestsByTop[top.id])
          ? guestsByTop[top.id]
          : new Set<string>();
        return GuestsHelper.filterByTopConstraints(
          guest,
          watchFilterByParty ? watchPartyId : '',
          topConstraints,
          guestsByParty[guest.partyId]!,
          top,
          guestsOfTop
        );
      })
      .sort((a, b) => GuestsHelper.compare(a, b));
    return possibleGuests;
  }, [watchPartyId, watchFilterByParty, guests, topConstraints, guestsByParty, top, guestsByTop]);

  useEffect(() => {
    if (
      watchFilterByParty &&
      parties.length > 0 &&
      (__.IsNullOrUndefinedOrEmpty(defaultPartyId) || __.IsNullOrUndefinedOrEmpty(defaultGuestId))
    ) {
      const possibleGuests: Guest[] = guests.filter((guest: Guest) => {
        const guestsOfTop = !__.IsNullOrUndefinedOrEmpty(guestsByTop[top.id])
          ? guestsByTop[top.id]
          : new Set<string>();
        return GuestsHelper.filterByTopConstraints(
          guest,
          '',
          topConstraints,
          guestsByParty[guest.partyId]!,
          top,
          guestsOfTop
        );
      });
      const possibleGuestsPartyIds = new Set(possibleGuests.map((guest) => guest.partyId));
      const possibleParties = [...parties]
        .filter((p) => possibleGuestsPartyIds.has(p.id))
        .sort((a, b) => PartiesHelper.compare(a, b));

      const partyId = __.IsNullOrUndefinedOrEmpty(possibleParties) ? '' : possibleParties[0].id;
      const guestId = __.IsNullOrUndefinedOrEmpty(possibleGuests)
        ? ''
        : possibleGuests.sort((a, b) => GuestsHelper.compare(a, b))[0].id;
      setDefaultPartyId(partyId);
      setDefaultGuestId(guestId);
    }
  }, [
    watchFilterByParty,
    parties,
    guests,
    topConstraints,
    watchPartyId,
    defaultPartyId,
    defaultGuestId,
    guestsByParty,
    top,
    guestsByTop,
  ]);

  useEffect(() => {
    if (
      watchFilterByParty &&
      !__.IsNullOrUndefinedOrEmpty(watchPartyId) &&
      filteredGuests.length > 0
    ) {
      setDefaultGuestId(filteredGuests[0].id);
      setValue('guestId', filteredGuests[0].id);
    }
  }, [watchFilterByParty, watchPartyId, filteredGuests, setValue]);

  const onSubmit = (values: any) => {
    if (
      __.IsNullOrUndefinedOrEmpty(values.guestId) ||
      !isValidUuid(values.guestId) ||
      !filteredGuests.some((guest) => guest.id === values.guestId)
    ) {
      return;
    }

    const guest = guests.find((g) => g.id === values.guestId)!;
    const guestsOfSameParty: Set<string> = guestsByParty[guest.partyId]!;
    const topConstraints: TopConstraint[] = Array.from(guestsOfSameParty).map((guestId) => {
      return {
        guestId: guestId,
        topId: props.topId,
      };
    });

    dispatch(topConstraintsActions.addMultiple(topConstraints));
    if (props.onSubmit) {
      props.onSubmit();
    }
  };

  return (
    <ModalContent mx={isMobile ? 4 : 0}>
      <ModalHeader>Add Guest to Table</ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <FormControl display='flex' alignItems='center'>
            <FormLabel>Filter by party</FormLabel>
            <Switch id='filter-by-party' {...register('filterByParty')} />
          </FormControl>

          {watchFilterByParty && (
            <FormControl isInvalid={touchedFields.partyId && errors.partyId}>
              <FormLabel>
                Party <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <Select
                styles={getCustomStyles(colorMode)}
                options={filteredParties.map((party) => ({
                  value: party.id,
                  label: PartiesHelper.getName(party),
                }))}
                onChange={(option) => setValue('partyId', option?.value || '')}
                defaultValue={{
                  value: defaultPartyId,
                  label: PartiesHelper.getName(filteredParties[0]),
                }}
                isSearchable
                placeholder='Search party...'
              />
            </FormControl>
          )}

          <FormControl isInvalid={touchedFields.guestId && errors.guestId}>
            <FormLabel>
              Guest <span style={{ color: 'red' }}>*</span>
            </FormLabel>
            <Select
              styles={getCustomStyles(colorMode)}
              options={filteredGuests.map((guest) => ({
                value: guest.id,
                label: guest.name,
              }))}
              onChange={(option) => setValue('guestId', option?.value || '')}
              defaultValue={{ value: defaultGuestId, label: filteredGuests[0].name }}
              isSearchable
              placeholder='Search guest...'
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            marginTop={4}
            variant='outline'
            color='black'
            backgroundColor='pastelMint'
            isLoading={isSubmitting}
            type='submit'
          >
            Add Guest
          </Button>
        </ModalFooter>
      </form>
    </ModalContent>
  );
};

export default AddTopConstraint;
