import { GuestConstraint, GuestConstraintType } from 'centerpiece-algorithm-client';

export const ConstraintsHelper = {
  /**
   * Compares two guests constraints
   * @param a
   * @param b
   * @returns
   */
  compare(constraint1: GuestConstraint, constraint2: GuestConstraint): number {
    const order = {
      [GuestConstraintType.must]: 0,
      [GuestConstraintType.should]: 1,
      [GuestConstraintType.shouldNot]: 2,
      [GuestConstraintType.mustNot]: 3,
    };
    const type1 = constraint1.guestConstraintType!;
    const type2 = constraint2.guestConstraintType!;

    // Compare the guestConstraintType values based on the predefined order
    if (order[type1] < order[type2]) {
      return -1;
    } else if (order[type1] > order[type2]) {
      return 1;
    } else {
      return 0;
    }
  },
};
