// theme.ts
import { BoxProps, extendTheme, ThemeConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const colors = {
  pastelMint: '#A0C49D',
  pastelRed: '#E97777',
  pastelBlue: '#A1CCD1',
  pastelOrange: '#E9B384',
  pastelPurple: '#545B77',
};

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const theme = extendTheme({
  colors,
  config,
  styles: {
    global: (props: BoxProps) => ({
      body: {
        fontFamily: 'body',
        color: mode('gray.800', 'whiteAlpha.900')(props),
        bg: mode('white', 'gray.800')(props),
      },
      '@media print': {
        body: {
          color: 'black', // Set the text color for printing to black
        },
      },
      '.dialog': {
        borderRadius: 'md',
        p: 4,
        boxShadow: mode('md', 'dark-lg')(props),
        bg: mode('white', 'gray.700')(props),
      },
      'input, textarea, select': {
        bg: mode('gray.100', 'gray.700')(props),
        color: mode('gray.800', 'white')(props),
        border: '1px solid',
        borderColor: mode('gray.300', 'gray.600')(props),
      },
      IconButton: {
        borderRadius: 'md',
        _hover: {
          bg: mode('gray.100', 'gray.600')(props),
        },
        _active: {
          bg: mode('gray.200', 'gray.500')(props),
        },
      },
    }),
  },
  components: {
    Switch: {
      baseStyle: {
        track: {
          _checked: {
            bg: 'pastelMint',
          },
          _focus: {
            boxShadow: 'none',
          },
        },
        thumb: {
          _checked: {
            borderColor: 'pastelMint',
          },
        },
      },
    },
  },
});

export default theme;
