import * as React from 'react';
import { Flex, Heading } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Content from './content';

export const HEADER_HEIGHT = 60;

const Header: React.FunctionComponent = () => {
  return (
    <Content centerProps={{ height: `${HEADER_HEIGHT}px` }}>
      <Flex align='center' as='header'>
        <Link to='/'>
          <Heading
            className='print-hide'
            marginTop={10}
            size='sm'
            color='pastelMint'
            fontFamily='BarokahSignature'
          >
            SeatingQuest
          </Heading>
        </Link>
      </Flex>
    </Content>
  );
};

export default Header;
