import { GuestConstraintType } from 'centerpiece-algorithm-client';

export const EnumHelper = {
  GetConstraintTypeDisplayName(
    guestConstraintType: GuestConstraintType,
    short: boolean = false
  ): string {
    switch (guestConstraintType) {
      case GuestConstraintType.must:
        return short ? 'Must' : 'Must Sit With';
      case GuestConstraintType.mustNot:
        return short ? 'Must Not' : 'Must Not Sit With';
      case GuestConstraintType.should:
        return short ? 'Should' : 'Should Sit With';
      case GuestConstraintType.shouldNot:
        return short ? 'Should Not' : 'Should Not Sit With';
      default:
        return '';
    }
  },
};
