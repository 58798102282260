import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Modal,
  ModalOverlay,
  SimpleGrid,
  useDisclosure,
  Text,
  Spacer,
} from '@chakra-ui/react';
import { Guest } from 'centerpiece-algorithm-client';
import * as React from 'react';
import { useSelector } from 'react-redux';
import UploadData from '../components/upload-data';
import ExportData from '../components/export-data';
import Content from '../components/reusable/layout/content';
import Page from '../components/reusable/layout/page';
import PageHeader from '../components/reusable/layout/page-header';
import Party from '../components/reusable/party';
import { RootState } from '../store/store';
import { IParty, IPartyWithGuests } from '../types/party';
import AddParty from '../components/guests/add-party';
import GenerateData from '../components/guests/generate-data';
import { DownloadIcon, PlusSquareIcon, RepeatIcon } from '@chakra-ui/icons';
import useMobile from '../hooks/useMobile';
import GuestStatistics from '../components/reusable/statistics';

interface ISetupGuestsProps {}

const mapGuestsToParties = (guests: Guest[], parties: IParty[]) => {
  const partiesWithGuests: IPartyWithGuests[] = parties.map((party) => ({
    ...party,
    guests: [],
  }));
  guests.forEach((guest) => {
    const party = partiesWithGuests.find(
      // @ts-ignore
      (party: IParty) => party.id === guest.partyId
    );
    party?.guests.push({ ...guest });
  });
  return partiesWithGuests;
};

const SetupGuests: React.FunctionComponent<ISetupGuestsProps> = (props) => {
  const isMobile = useMobile();
  const guests = useSelector((state: RootState) => state.guests);
  const parties = useSelector((state: RootState) => state.parties);
  const { isOpen: isOpenImport, onClose: onCloseImport, onOpen: onOpenImport } = useDisclosure();
  const { isOpen: isOpenExport, onClose: onCloseExport, onOpen: onOpenExport } = useDisclosure();
  const { isOpen: isOpenParty, onClose: onCloseParty, onOpen: onOpenParty } = useDisclosure();
  const {
    isOpen: isOpenGenerateData,
    onClose: onCloseGenerateData,
    onOpen: onOpenGenerateData,
  } = useDisclosure();

  const renderButtons = () => {
    if (isMobile) {
      return (
        <ButtonGroup>
          <IconButton
            variant='outline'
            icon={<PlusSquareIcon />}
            onClick={onOpenParty}
            aria-label='Add Party'
          />
          <IconButton
            variant='outline'
            icon={<DownloadIcon transform='rotate(180deg)' />}
            onClick={onOpenImport}
            aria-label='Import'
          />
          <IconButton
            variant='outline'
            icon={<DownloadIcon />}
            onClick={onOpenExport}
            aria-label='Export Data'
          />
          <IconButton
            variant='outline'
            icon={<RepeatIcon />}
            onClick={onOpenGenerateData}
            aria-label='Generate Guests'
          />
        </ButtonGroup>
      );
    } else {
      return (
        <Flex>
          <ButtonGroup>
            <Button leftIcon={<PlusSquareIcon />} onClick={onOpenParty}>
              Add Party
            </Button>
            <Button leftIcon={<DownloadIcon transform='rotate(180deg)' />} onClick={onOpenImport}>
              Import Data
            </Button>
            <Button leftIcon={<DownloadIcon />} onClick={onOpenExport}>
              Export Data
            </Button>
            <Button leftIcon={<RepeatIcon />} onClick={onOpenGenerateData}>
              Generate Guests
            </Button>
          </ButtonGroup>
          <Spacer />
          <GuestStatistics />
        </Flex>
      );
    }
  };

  const partiesWithGuests = React.useMemo(
    () => mapGuestsToParties(guests, parties),
    [guests, parties]
  );

  const renderNoDataMessage = () => {
    const blockSpacing = 7;
    return (
      <Box textAlign='start' marginTop='8'>
        <Text fontSize='xl' fontWeight='bold' color='gray.500' marginBottom={blockSpacing}>
          If you already have a guest list prepared, choose the <Text as='i'>Import Data</Text>{' '}
          option to find our file format (based on TheKnot.com) and upload your guests. browser.
        </Text>

        <Text fontSize='xl' fontWeight='bold' color='gray.500' marginBottom={blockSpacing}>
          If you want to find out if SeatingQuest is right for you, see how it works by clicking
          "Generate Data" and choosing the number of guests for your sample event.
        </Text>

        <Text fontSize='xl' fontWeight='bold' color='gray.500' marginBottom={blockSpacing}>
          Finally, you want to build your guest list directly on SeatingQuest, start by clicking{' '}
          <Text as='i'>Add Party</Text>. Don't forget to export your data from time to time since it
          is only stored on your local browser.
        </Text>
      </Box>
    );
  };

  const renderGuestsContent = () => {
    if (partiesWithGuests.length === 0) {
      return renderNoDataMessage();
    }

    return (
      <SimpleGrid minChildWidth={[300, 500]} spacing='40px'>
        {partiesWithGuests.map((party, i) => (
          <Party
            displayName={party.displayName}
            computedName={party.computedName}
            guests={party.guests}
            id={party.id}
            key={i}
          />
        ))}
      </SimpleGrid>
    );
  };

  return (
    <>
      <Page>
        <Content>
          <PageHeader>Manage Guests</PageHeader>
          {renderButtons()}
          {renderGuestsContent()}
        </Content>
      </Page>
      <Modal isOpen={isOpenImport} onClose={onCloseImport}>
        <ModalOverlay />
        <UploadData onSubmit={onCloseImport} />
      </Modal>
      <Modal isOpen={isOpenExport} onClose={onCloseExport}>
        <ModalOverlay />
        <ExportData onSubmit={onCloseExport} />
      </Modal>
      <Modal isOpen={isOpenParty} onClose={onCloseParty}>
        <ModalOverlay />
        <AddParty onSubmit={onCloseParty} />
      </Modal>
      <Modal isOpen={isOpenGenerateData} onClose={onCloseGenerateData}>
        <ModalOverlay />
        <GenerateData onSubmit={onCloseGenerateData} />
      </Modal>
    </>
  );
};

export default SetupGuests;
