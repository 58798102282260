import * as React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  FormLabel,
  HStack,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  useColorMode,
} from '@chakra-ui/react';
import { TopShapeEnum, Top as ITop } from 'centerpiece-algorithm-client';
import { useDispatch } from 'react-redux';
import { topsActions } from '../../store/slices/tops-slice';
import TopName from './top-name';
import TopConstraints from './top-constraints';
import { topConstraintsActions } from '../../store/slices/top-constraints-slice';
import { CgCloseR } from 'react-icons/cg';
import useMobile from '../../hooks/useMobile';

const Top: React.FunctionComponent<ITop> = (props: ITop) => {
  const isMobile = useMobile();
  const { colorMode } = useColorMode();
  const { maxCapacity, id, shape } = props;
  const dispatch = useDispatch();
  const cancelRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const handleRemoveTopClick = () => {
    setIsOpenAlert(true);
  };

  const handleDeleteConfirmed = () => {
    dispatch(topConstraintsActions.deleteForTop(id));
    dispatch(topsActions.delete(id));
    setIsOpenAlert(false);
  };

  const handleDeleteCancelled = () => {
    setIsOpenAlert(false);
  };

  const [isOpenAlert, setIsOpenAlert] = React.useState(false);
  const renderButtons = () => {
    if (isMobile) {
      return (
        <IconButton
          icon={<CgCloseR />}
          variant='outline'
          color='pastelRed'
          onClick={handleRemoveTopClick}
          size={'sm'}
          aria-label={''}
        ></IconButton>
      );
    } else {
      return (
        <Button
          leftIcon={<CgCloseR />}
          variant='outline'
          color='pastelRed'
          onClick={handleRemoveTopClick}
        >
          Remove Table
        </Button>
      );
    }
  };

  const handleCapacityChange = (_: string, value: number) => {
    dispatch(
      topsActions.update({
        id: id,
        top: {
          maxCapacity: value,
        },
      })
    );
  };

  const handleChangeShape = ($event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(
      topsActions.update({
        id: id,
        top: {
          shape: $event.target.value as TopShapeEnum,
        },
      })
    );
  };

  return (
    <>
      <Card variant={colorMode === 'light' ? 'outline' : 'elevated'} marginTop='4'>
        <CardHeader display='flex' justifyContent='space-between'>
          <TopName topId={id} />
          {renderButtons()}
        </CardHeader>
        <CardBody>
          <HStack marginBottom={5} spacing='24px'>
            <Box maxWidth='50%'>
              <FormLabel>Number of Seats</FormLabel>
              <NumberInput
                defaultValue={8}
                min={1}
                max={200}
                value={maxCapacity}
                onChange={handleCapacityChange}
              >
                <NumberInputField id='seats' type='number' />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Box>
            <Box maxWidth='50%'>
              <FormLabel>Table shape</FormLabel>
              <Select value={shape} onChange={handleChangeShape}>
                <option value={TopShapeEnum.circle}>circle</option>
                <option value={TopShapeEnum.rectangle}>rectangle</option>
              </Select>
            </Box>
          </HStack>
          <TopConstraints topId={id} />
        </CardBody>
      </Card>
      <AlertDialog
        isOpen={isOpenAlert}
        leastDestructiveRef={cancelRef}
        onClose={handleDeleteCancelled}
        autoFocus={true}
        motionPreset='slideInBottom'
      >
        <AlertDialogOverlay />
        <AlertDialogContent mx={isMobile ? 4 : 0}>
          <AlertDialogHeader>Confirm Deletion</AlertDialogHeader>
          <AlertDialogBody>
            Are you sure you want to delete this table and related table constraints?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={handleDeleteCancelled}>Cancel</Button>
            <Button
              color='black'
              backgroundColor='pastelRed'
              onClick={handleDeleteConfirmed}
              ml={3}
              ref={(ref) => ref && ref.focus()}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default Top;
