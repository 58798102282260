import React, { useRef, useEffect } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import useMobile from '../../hooks/useMobile';
import { AlertType } from '../../types/alert-type.enum';

interface DeleteConfirmationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onCancel: () => void;
  alertType: AlertType | null;
}

const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = ({
  isOpen,
  onClose,
  onConfirm,
  onCancel,
  alertType,
}) => {
  const handleDeleteConfirmed = () => {
    onConfirm();
    onClose();
  };

  const handleDeleteCancelled = () => {
    onCancel();
    onClose();
  };

  // Create a ref for the "Delete" button
  const deleteButtonRef = useRef<HTMLButtonElement | null>(null);

  // Set the initial focus to the "Delete" button when the component mounts
  useEffect(() => {
    if (deleteButtonRef.current) {
      deleteButtonRef.current.focus();
    }
  }, []);

  const isMobile = useMobile();
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={deleteButtonRef}
      onClose={handleDeleteCancelled}
      autoFocus={true}
      motionPreset='slideInBottom'
    >
      <AlertDialogOverlay />
      <AlertDialogContent mx={isMobile ? 4 : 0}>
        <AlertDialogHeader>Confirm Deletion</AlertDialogHeader>
        <AlertDialogBody>
          {alertType === AlertType.GUEST && 'Are you sure you want to delete this guest?'}
          {alertType === AlertType.PARTY &&
            'Are you sure you want to delete this party with all its guests?'}
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button onClick={handleDeleteCancelled}>Cancel</Button>
          <Button
            color='black'
            backgroundColor='pastelRed'
            onClick={handleDeleteConfirmed}
            ml={3}
            ref={deleteButtonRef}
          >
            Delete
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeleteConfirmationDialog;
