import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IResult } from '../../types/result';
import { ResultVisualization } from '../../types/result-visualization.enum';

const initialState: IResult = {
  solverStatus: null,
  assignments: [],
  constraintReport: null,
  resultVisualization: ResultVisualization.CARDS,
};
export const resultSlice = createSlice({
  name: 'result',
  initialState,
  reducers: {
    replace: (_state, action: PayloadAction<IResult>) => {
      return action.payload;
    },
    clear: () => {
      return initialState;
    },
    setVisualization: (state, action: PayloadAction<ResultVisualization>) => {
      state.resultVisualization = action.payload;
    },
  },
});

export const resultActions = resultSlice.actions;

export default resultSlice.reducer;
