import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { TopConstraint } from 'centerpiece-algorithm-client';
import { guestsActions } from './guests-slice';
import { topsActions } from './tops-slice';

const initialState: Array<TopConstraint> = [];
export const topConstraintsSlice = createSlice({
  name: 'top-constraints',
  initialState,
  reducers: {
    replace: (state, action: PayloadAction<TopConstraint[]>) => {
      return action.payload;
    },
    add: (state, action: PayloadAction<TopConstraint>) => {
      return [...state, action.payload];
    },
    addMultiple: (state, action: PayloadAction<TopConstraint[]>) => {
      return [...state, ...action.payload];
    },
    delete: (state, action: PayloadAction<{ guestId: string; topId: string }>) => {
      return state.filter(
        constraint =>
          constraint.guestId !== action.payload.guestId || constraint.topId !== action.payload.topId
      );
    },
    deleteForGuest: (state, action: PayloadAction<string>) => {
      return state.filter(constraint => constraint.guestId !== action.payload);
    },
    deleteForTop: (state, action: PayloadAction<string>) => {
      return state.filter(constraint => constraint.topId !== action.payload);
    },
    clear: () => {
      return [];
    },
  },
  extraReducers: builder => {
    builder

      .addCase(guestsActions.clear, () => {
        return [];
      })
      .addCase(guestsActions.replace, () => {
        return [];
      })
      .addCase(guestsActions.delete, (state, action: PayloadAction<string>) => {
        return state.filter(constraint => constraint.guestId !== action.payload);
      })

      .addCase(topsActions.clear, () => {
        return [];
      })
      .addCase(topsActions.delete, (state, action: PayloadAction<string>) => {
        return state.filter(constraint => constraint.topId !== action.payload);
      });
  },
});

// Action creators are generated for each case reducer function
export const topConstraintsActions = topConstraintsSlice.actions;

export default topConstraintsSlice.reducer;
