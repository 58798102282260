import React from 'react';
import { Guest } from 'centerpiece-algorithm-client';
import { StarIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { Grid, GridItem, ButtonGroup, IconButton, Box } from '@chakra-ui/react';
import { ConstraintsHelper } from '../../util/constraints.function';
import { __ } from '../../util/object.function';
import { getIconByGuestConstraintType } from '../reusable/custom-arrow-icon';
import useMobile from '../../hooks/useMobile';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { RootState } from '../../store/store';
import { TbSettingsPlus } from 'react-icons/tb';
import { ActionEnum } from '../../types/action.enum';

interface GuestItemProps {
  guest: Guest;
  currentGuestId: string | null;
  handleGuestClick: (guestId: string, action: ActionEnum, $event: React.MouseEvent) => void;
}

// Create a selector function to get the raw state.topConstraints
const rawGuestConstraintsSelector = (state: RootState) => state.guestConstraints;
// Create a memoized selector using createSelector
export const guestConstraintsSelector = createSelector(
  [rawGuestConstraintsSelector, (_: RootState, guest: Guest) => guest],
  (guestConstraints, guest) =>
    guestConstraints.filter(
      (constraint) => constraint.guestId1 === guest.id || constraint.guestId2 === guest.id
    )
);

const GuestItem: React.FunctionComponent<GuestItemProps> = ({
  guest,
  currentGuestId,
  handleGuestClick,
}) => {
  const isMobile = useMobile();
  const guestConstraints = useSelector((state: RootState) =>
    guestConstraintsSelector(state, guest)
  );

  return (
    <Grid templateColumns={isMobile ? `6fr 2fr 2fr` : `repeat(3, 1fr)`} gap={2} width='100%'>
      {/* Name */}
      <GridItem width='100%' display='flex' alignItems='center'>
        <Box
          maxWidth={[120, 220, 200, 300]}
          marginLeft={guest.isSpecial ? '36px' : '0'}
          overflow='hidden'
          textOverflow='ellipsis'
        >
          {guest.name}
        </Box>
      </GridItem>
      {/* preferences in not mobile view*/}
      {!isMobile && (
        <GridItem width='100%' display='flex' alignItems='center'>
          {guestConstraints
            .sort((constraint1, constraint2) => ConstraintsHelper.compare(constraint1, constraint2))
            .map((constraint) => (
              <Box marginRight={1} key={`${constraint.guestId1}_${constraint.guestId2}`}>
                {getIconByGuestConstraintType(constraint.guestConstraintType!)}
              </Box>
            ))}

          {/* IS SPECIAL */}
          {guest.isSpecial && <StarIcon color='pastelMint' marginLeft='26px' key={guest.id} />}
        </GridItem>
      )}
      {/* preferences in mobile view*/}
      {isMobile && (
        <GridItem width='100%' display='flex' alignItems='center'>
          {/* GUEST-CONSTRAINTS */}
          {!__.IsNullOrUndefinedOrEmpty(
            guestConstraints.filter(
              (constraint) => constraint.guestId1 === guest.id || constraint.guestId2 === guest.id
            )
          ) && <StarIcon color='pastelPurple' key={guest.id} />}
          {/* IS SPECIAL */}
          {guest.isSpecial && <StarIcon color='pastelMint' key={guest.id} />}
        </GridItem>
      )}
      {/* BUTTONS */}
      {currentGuestId === guest.id && (
        <GridItem width='100%' textAlign='right'>
          <ButtonGroup>
            {!guest.isSpecial && (
              <IconButton
                aria-label='Add preference'
                icon={<TbSettingsPlus />}
                variant='solid'
                color='black'
                borderColor='pastelMint'
                background='pastelMint'
                size={'sm'}
                onClick={(event) => handleGuestClick(guest.id, ActionEnum.PREFERENCE, event)}
              />
            )}
            <IconButton
              aria-label='Edit guest'
              icon={<EditIcon />}
              variant='solid'
              color='black'
              borderColor='pastelMint'
              background='pastelMint'
              size={'sm'}
              onClick={(event) => handleGuestClick(guest.id, ActionEnum.EDIT, event)}
            />
            <IconButton
              aria-label='Delete guest'
              icon={<DeleteIcon />}
              variant='solid'
              color='black'
              borderColor='pastelRed'
              background='pastelRed'
              size={'sm'}
              onClick={(event) => handleGuestClick(guest.id, ActionEnum.DELETE, event)}
            />
          </ButtonGroup>
        </GridItem>
      )}
    </Grid>
  );
};

export default GuestItem;
