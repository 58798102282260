import React, { useState } from 'react';
import p5 from 'p5';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Guest, TopShapeEnum, Top } from 'centerpiece-algorithm-client';
import { ResultHelper } from '../../util/result.function';
import { topsActions } from '../../store/slices/tops-slice';
import { __ } from '../../util/object.function';
import { TopsHelper } from '../../util/tops.function';

const GraphSettings = {
  textColor: [0],
  seatBackGroundColor: '#A0C49D',
  topBackgroundColor: '#A0C49D',
  seatLength: 60,
  seatWidth: 60,
  topTextSize: 16,
  seatTextSize: 10,
};

const TopAssignmentGraph: React.FunctionComponent = () => {
  // we cannot use the normal hook here, it does not work
  const isMobile = window.innerWidth <= 767;
  const dispatch = useDispatch();
  const [isDragging, setIsDragging] = useState(false);
  const [initialized, setInitialized] = React.useState(false);
  const result = useSelector((state: RootState) => state.result);
  const topsFromStore = useSelector((state: RootState) => state.tops);
  const guests = useSelector((state: RootState) => state.guests);

  const guestMap: Map<string, Guest> = ResultHelper.generateGuestMap(guests);
  const topGuestsMap: Map<string, Array<string>> = ResultHelper.generateTopGuestMap(
    topsFromStore,
    result.assignments,
    guestMap
  );

  const scaleFactor = isMobile ? 0.5 : 1;

  // compute (x,y) positions for tops, and set shape, length, and width if not already done
  const topMargin = 180 * scaleFactor; // Space between edge of canvas and first row/col of tables
  const topColumns = Math.floor(Math.sqrt(topsFromStore.length));
  const topRows = Math.ceil(topsFromStore.length / topColumns);
  let currentRow = 0;

  // ensuring mandatory properties are set and stored
  let tops = topsFromStore
    .map((top) => top)
    .sort((a, b) => TopsHelper.compare(a, b))
    .map((topFromStore, index) => {
      currentRow = Math.floor(index / topColumns);
      let top = { ...topFromStore }; // Create a shallow copy of the 'top' object
      if (!top.shape) {
        top.shape = TopShapeEnum.circle;
      }
      top.length = 160 * scaleFactor;
      top.width = 160 * scaleFactor;
      // if (!top.x || !top.y) { <-- not persisting for now
      top.x =
        topMargin * scaleFactor +
        top.length! / 2 +
        (index % topColumns) * (topMargin + top.length!);
      top.y = topMargin * scaleFactor + top.length! / 2 + currentRow * (topMargin + top.length!);
      // }
      return top;
    });

  // Compute size of canvas required to fit all tables
  // ToDo: Needs to be updated when tables aren't all the same size (in visualization)
  let topSize = !__.IsNullOrUndefinedOrEmpty(tops) ? tops[0].length! : 180;

  // better to just apply the logic from the container with some margin
  const canvasWidth = isMobile ? 350 : 1232; // width of container
  const canvasHeight = topRows * (topSize + topMargin) + topMargin;

  const calculateSeatToSideMap = (top: Top) => {
    let seatToSide: Map<number, number> = new Map();
    let seatSideIndices: Map<number, number> = new Map();
    let sideToSeats: Map<number, number> = new Map();
    let remainingSeats = top.maxCapacity;
    let remainingSides = 4;
    let seatsOnCurrentSide;
    let seat;
    let previousSeat = 0;

    for (let side = 0; side < 4; side++) {
      let seatsPerSide = (1 * remainingSeats) / remainingSides;
      seatsOnCurrentSide = Math.ceil(seatsPerSide);
      for (seat = previousSeat; seat < previousSeat + seatsOnCurrentSide; seat++) {
        seatToSide.set(seat, side);
        seatSideIndices.set(seat, seat - previousSeat);
        if (sideToSeats.has(side)) {
          sideToSeats.set(side, sideToSeats.get(side)! + 1);
        } else {
          sideToSeats.set(side, 1);
        }
      }
      previousSeat = seat;
      remainingSeats = top.maxCapacity - previousSeat;
      remainingSides = 3 - side;
    }
    return { seatToSide: seatToSide, sideToSeats: sideToSeats, seatSideIndices: seatSideIndices };
  };

  const calculateSeatPosition = (
    top: Top,
    seatIndex: number,
    side = 0,
    seatsPerSide = 0,
    seatSideIndex = 0
  ) => {
    if (top.shape === TopShapeEnum.circle) {
      let seatDistance = (top.length! + GraphSettings.seatLength! * scaleFactor) / 2;
      let seatX = seatDistance * Math.sin((2 * Math.PI * seatIndex) / top.maxCapacity);
      let seatY = seatDistance * Math.cos((2 * Math.PI * seatIndex) / top.maxCapacity);

      return { seatX, seatY };
    }
    const seatDistanceX = top.width! / (seatsPerSide * 2);
    const seatDistanceY = top.length! / (seatsPerSide * 2);

    let seatX = 0;
    let seatY = 0;

    switch (side) {
      case 0: // Top side
        seatX = seatDistanceX * (2 * seatSideIndex + 1);
        seatY = (-GraphSettings.seatLength / 2) * scaleFactor;
        break;
      case 1: // Right side
        seatX = top.width! + (GraphSettings.seatWidth / 2) * scaleFactor;
        seatY = seatDistanceY * (2 * seatSideIndex + 1);
        break;
      case 2: // Bottom side
        seatX = top.width! - seatDistanceX * (2 * seatSideIndex + 1);
        seatY = top.length! + (GraphSettings.seatLength / 2) * scaleFactor;
        break;
      case 3: // Left side
        seatX = (-GraphSettings.seatWidth / 2) * scaleFactor;
        seatY = top.length! - seatDistanceY * (2 * seatSideIndex + 1);
        break;
      default:
        break;
    }

    // we need to consider the coordinates from the center of the table
    seatX -= top.width! / 2;
    seatY -= top.length! / 2;
    return { seatX, seatY };
  };

  function drawStar(
    p5: p5,
    x: number,
    y: number,
    radius1: number,
    radius2: number,
    npoints: number,
    rotation: number
  ) {
    const angle = p5.TWO_PI / npoints;
    const halfAngle = angle / 2.0;
    p5.beginShape();
    for (let a = -p5.PI / 2 + rotation; a < p5.TWO_PI - p5.PI / 2 + rotation; a += angle) {
      const x1 = x + p5.cos(a) * radius2;
      const y1 = y + p5.sin(a) * radius2;
      p5.vertex(x1, y1);
      const x2 = x + p5.cos(a + halfAngle) * radius1;
      const y2 = y + p5.sin(a + halfAngle) * radius1;
      p5.vertex(x2, y2);
    }
    p5.endShape(p5.CLOSE);
  }

  const touchMoveHandler = React.useCallback(
    ($event: TouchEvent) => {
      if (isDragging) {
        $event.preventDefault();
      }
    },
    [isDragging]
  );

  React.useEffect(() => {
    document.addEventListener('touchmove', touchMoveHandler, { passive: false });

    return () => {
      document.removeEventListener('touchmove', touchMoveHandler);
    };
  }, [isDragging, touchMoveHandler]);

  // Now enter the p5js part of the code
  const sketch = (p5: p5) => {
    p5.setup = () => {
      p5.createCanvas(canvasWidth, canvasHeight);
      p5.pixelDensity(window.devicePixelRatio); // Set the pixel density based on the device
    };

    // Prepare some variables we will use for moving tops
    let offsetX: number,
      offsetY: number = 0;
    let draggedTop: Top | null;

    p5.draw = () => {
      // prevents weird animations
      p5.clear(0, 0, 0, 0);
      for (const top of tops) {
        p5.fill(GraphSettings.topBackgroundColor);
        let seatToSide: Map<number, number> = new Map();
        let sideToSeats: Map<number, number> = new Map();
        let seatSideIndices: Map<number, number> = new Map();
        switch (top.shape) {
          case TopShapeEnum.circle:
            p5.circle(top.x!, top.y!, top.length!);
            break;
          case TopShapeEnum.rectangle:
            p5.rect(top.x! - top.width! / 2, top.y! - top.length! / 2, top.width!, top.length!);
            let overview = calculateSeatToSideMap(top);
            seatToSide = overview.seatToSide;
            sideToSeats = overview.sideToSeats;
            seatSideIndices = overview.seatSideIndices;
            break;
          default:
            console.warn(`TopShape ${top.shape} is not implemented`);
            break;
        }
        p5.textSize(GraphSettings.topTextSize * scaleFactor);
        p5.fill(GraphSettings.textColor);
        p5.textAlign(p5.CENTER, p5.CENTER);
        p5.text(top.name!, top.x!, top.y!);

        if (top.isSpecial) {
          const starRadius1 = 10 * scaleFactor;
          const starRadius2 = 4 * scaleFactor;
          const starNPoints = 5;
          const starX = top.x!;
          const starY = top.y! + top.length! / 6;
          const starRotation = p5.PI;
          p5.fill(GraphSettings.textColor);
          drawStar(p5, starX, starY, starRadius1, starRadius2, starNPoints, starRotation);
        }

        // draw seats
        for (let seatIndex = 0; seatIndex < top.maxCapacity; seatIndex++) {
          const side = top.shape === TopShapeEnum.circle ? 0 : seatToSide.get(seatIndex)!;
          const seatsForSide = top.shape === TopShapeEnum.circle ? 0 : sideToSeats.get(side)!;
          const seatSideIndex =
            top.shape === TopShapeEnum.circle ? 0 : seatSideIndices.get(seatIndex)!;
          const { seatX, seatY } = calculateSeatPosition(
            top,
            seatIndex,
            side,
            seatsForSide,
            seatSideIndex
          );
          p5.fill(GraphSettings.seatBackGroundColor);
          p5.circle(top.x! + seatX, top.y! + seatY, GraphSettings.seatLength * scaleFactor);
          if (seatIndex < topGuestsMap.get(top.id)!.length) {
            let guest: Guest | undefined = guestMap.get(topGuestsMap.get(top.id)![seatIndex]);
            if (!guest) {
              continue;
            }
            p5.textAlign(p5.CENTER, p5.CENTER);
            p5.textSize(GraphSettings.seatTextSize * scaleFactor);
            p5.fill(GraphSettings.textColor);
            // When using textWrap(), you must specify a text box width in text(), but then the
            // textAlign() method centers the box instead of the text. So had to subtract half the
            // seat length along the horizontal axis to compensate.
            // p5.textWrap(p5.CHAR);
            p5.text(
              guest.name,
              top.x! + seatX - (GraphSettings.seatLength * scaleFactor) / 2,
              top.y! + seatY,
              GraphSettings.seatLength * scaleFactor
            );
            p5.textAlign(p5.CENTER, p5.CENTER);
          }
        }
      }
    };

    p5.mousePressed = () => {
      setIsDragging(false);
      for (const top of tops) {
        if (p5.dist(p5.mouseX, p5.mouseY, top.x!, top.y!) < top.length! / 2) {
          draggedTop = top;
          offsetX = p5.mouseX - top.x!;
          offsetY = p5.mouseY - top.y!;
          setIsDragging(true);
          break;
        }
      }
    };

    p5.touchStarted = () => {
      setIsDragging(false);
      for (const top of tops) {
        if (p5.dist(p5.mouseX, p5.mouseY, top.x!, top.y!) < top.length! / 2) {
          draggedTop = top;
          offsetX = p5.mouseX - top.x!;
          offsetY = p5.mouseY - top.y!;
          setIsDragging(true);
          break;
        }
      }
    };

    p5.mouseDragged = () => {
      if (!draggedTop) {
        return;
      }
      // If a top is being dragged, update its position
      draggedTop.x = p5.mouseX - offsetX;
      draggedTop.y = p5.mouseY - offsetY;
    };

    p5.mouseReleased = () => {
      if (draggedTop) {
        dispatch(
          topsActions.update({
            id: draggedTop.id,
            top: draggedTop,
          })
        );
      }
      draggedTop = null;
      setIsDragging(false);
    };

    p5.touchEnded = () => {
      if (draggedTop) {
        dispatch(
          topsActions.update({
            id: draggedTop.id,
            top: draggedTop,
          })
        );
      }
      draggedTop = null;
      setIsDragging(false);
    };
  };

  return (
    <div
      style={{
        maxWidth: isMobile ? '350px' : '1232px',
        overflowX: 'hidden',
        padding: '8',
        width: 'full',
      }}
      ref={(element) => {
        if (element && !initialized) {
          setInitialized(true);
          return new p5(sketch, element);
        }
        return undefined;
      }}
    />
  );
};

export default TopAssignmentGraph;
