import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import guestsSlice from './slices/guests-slice';
import topsSlice from './slices/tops-slice';
import partiesSlice from './slices/parties-slice';
import guestConstraintsSlice from './slices/guest-constraints-slice';
import topConstraintsSlice from './slices/top-constraints-slice';
import { ValidationMiddleware } from './middleware';
import resultSlice from './slices/result-slice';

const reducers = combineReducers({
  guests: guestsSlice,
  tops: topsSlice,
  parties: partiesSlice,
  guestConstraints: guestConstraintsSlice,
  topConstraints: topConstraintsSlice,
  result: resultSlice,
});

export const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
  },
  reducers
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(ValidationMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
