import { Box, Center, CenterProps } from '@chakra-ui/react';
import * as React from 'react';

interface IContentProps {
  children?: React.ReactNode;
  centerProps?: CenterProps;
}

const Content: React.FunctionComponent<IContentProps> = (props) => {
  return (
    <Center {...props.centerProps}>
      <Box maxWidth='container.xl' width='full' padding={6}>
        {props.children}
      </Box>
    </Center>
  );
};

export default Content;
