import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
} from '@chakra-ui/react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { topsActions } from '../../store/slices/tops-slice';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from '../../store/store';
import { __ } from '../../util/object.function';
import useMobile from '../../hooks/useMobile';
import { TopShapeEnum } from 'centerpiece-algorithm-client';

interface ISetupUploadGuestsProps {
  onSubmit?: () => void;
}

const AddTop: React.FunctionComponent<ISetupUploadGuestsProps> = (props) => {
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const tops = useSelector((state: RootState) => state.tops);
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, touchedFields },
  } = useForm();

  const onSubmit = (values: any) => {
    if (__.IsNullOrUndefinedOrEmpty(values.maxCapacity)) {
      return;
    }
    if (!values.name) {
      values.name = `Table ${tops.length + 1}`;
    }
    dispatch(
      topsActions.add({
        ...values,
        id: uuidv4(),
      })
    );
    if (props.onSubmit) {
      props.onSubmit();
    }
  };

  return (
    <ModalContent mx={isMobile ? 4 : 0}>
      <ModalHeader>Add Table</ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <FormControl>
            <FormLabel>Table</FormLabel>
            <Input id='name' data-1p-ignore {...register('name')} />
          </FormControl>
          <FormControl isInvalid={touchedFields.maxCapacity && errors.maxCapacity}>
            <FormLabel>
              Maximum Capacity <span style={{ color: 'red' }}>*</span>
            </FormLabel>
            <NumberInput defaultValue={8} min={1} max={200}>
              <NumberInputField
                id='maxCapacity'
                {...register('maxCapacity', { required: 'Maximum Capacity is required', min: 1 })}
                type='number'
              />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            {errors.maxCapacity && (
              <FormErrorMessage>Maximum Capacitys is required</FormErrorMessage>
            )}
          </FormControl>

          <FormControl isInvalid={touchedFields.shape && errors.shape}>
            <FormLabel>Table shape</FormLabel>
            <Select id='shape-select' {...register('shape')}>
              <option value={TopShapeEnum.circle}>circle</option>
              <option value={TopShapeEnum.rectangle}>rectangle</option>
            </Select>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            marginTop={4}
            color='black'
            background='pastelMint'
            isLoading={isSubmitting}
            type='submit'
          >
            Continue
          </Button>
        </ModalFooter>
      </form>
    </ModalContent>
  );
};

export default AddTop;
