import * as React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Modal,
  ModalOverlay,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react';

import { IPartyWithGuests } from '../../types/party';
import AddUpdateGuest from '../guests/add-update-guest';
import { partiesActions } from '../../store/slices/parties-slice';
import { useDispatch } from 'react-redux';
import { guestsActions } from '../../store/slices/guests-slice';
import { useState } from 'react';
import PartyName from '../guests/party-name';
import guestConstraintsSlice, {
  guestConstraintsActions,
} from '../../store/slices/guest-constraints-slice';
import { topConstraintsActions } from '../../store/slices/top-constraints-slice';
import { PartiesHelper } from '../../util/parties.function';
import useMobile from '../../hooks/useMobile';
import { CgCloseR } from 'react-icons/cg';
import { BsPersonPlusFill } from 'react-icons/bs';
import GuestList from '../guests/guest-list';
import DeleteConfirmationDialog from '../guests/guest-alert-dialog';
import AddPreferenceModal from '../guests/add-preference-modal';
import { __ } from '../../util/object.function';
import { ActionEnum } from '../../types/action.enum';
import { AlertType } from '../../types/alert-type.enum';

const Party: React.FunctionComponent<IPartyWithGuests> = (props) => {
  const isMobile = useMobile();
  const { colorMode } = useColorMode();
  const { guests, id } = props;
  const { isOpen: isOpenModal, onClose: onCloseModal, onOpen: onOpenModal } = useDisclosure();
  const {
    isOpen: isOpenPreference,
    onClose: onClosePreference,
    onOpen: onOpenPreference,
  } = useDisclosure();
  const dispatch = useDispatch();

  const [currentGuestId, setCurrentGuestId] = useState<string | null>(null);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState<AlertType | null>(null);

  const numberOfColumns = isMobile ? 2 : 3;

  const handleMouseLeave = () => {
    if (!isOpenAlert && !isOpenModal && !isOpenPreference) {
      // Only set currentGuestId to null if no dialog is open,
      // otherwise, deleting does not work
      setCurrentGuestId(null);
    }
  };

  const handleGuestCreateClicked = () => {
    setCurrentGuestId(null);
    onOpenModal();
  };

  const handleGuestClick = (guestId: string, action: ActionEnum, $event: React.MouseEvent) => {
    $event.stopPropagation(); // Stop event propagation here to prevent triggering the accordion
    setCurrentGuestId(guestId);
    if (action === ActionEnum.EDIT) {
      onOpenModal();
    } else if (action === ActionEnum.PREFERENCE) {
      if (!__.IsNullOrUndefinedOrEmpty(guestConstraintsSlice)) {
        onOpenPreference();
      }
    } else {
      setAlertType(AlertType.GUEST);
      setIsOpenAlert(true);
    }
  };

  const handleRemovePartyClick = () => {
    setAlertType(AlertType.PARTY);
    setIsOpenAlert(true);
  };

  const renderRemovePartyButton = () => {
    if (isMobile) {
      return (
        <IconButton
          icon={<CgCloseR />}
          variant='outline'
          color='pastelRed'
          onClick={handleRemovePartyClick}
          aria-label={''}
          size={'sm'}
        ></IconButton>
      );
    } else {
      return (
        <Button
          leftIcon={<CgCloseR />}
          variant='outline'
          color='pastelRed'
          onClick={handleRemovePartyClick}
        >
          Remove Party
        </Button>
      );
    }
  };

  const handleDeleteConfirmed = () => {
    if (alertType === AlertType.GUEST) {
      dispatch(guestConstraintsActions.deleteForGuest(currentGuestId!));
      dispatch(topConstraintsActions.deleteForGuest(currentGuestId!));
      dispatch(guestsActions.delete(currentGuestId!));
      dispatch(
        partiesActions.updateCommputedName({
          id: id,
          computedName: PartiesHelper.joinCenterpieceStyle(
            guests.filter((guest) => guest.id !== currentGuestId)
          ),
          displayName: '',
        })
      );

      setCurrentGuestId(null);
      setIsOpenAlert(false);
    } else {
      for (const guest of guests) {
        dispatch(guestConstraintsActions.deleteForGuest(guest.id));
        dispatch(topConstraintsActions.deleteForGuest(guest.id));
        dispatch(guestsActions.delete(guest.id));
      }
      dispatch(partiesActions.delete(id));
    }
    setIsOpenAlert(false);
    setAlertType(null);
  };

  const handleDialogClosed = () => {
    onCloseModal();
    setCurrentGuestId(null);
  };

  return (
    <>
      <Card
        variant={colorMode === 'light' ? 'outline' : 'elevated'}
        marginTop='4'
        onMouseLeave={handleMouseLeave} // Use the custom event handler
      >
        <CardHeader display='flex' justifyContent='space-between'>
          <PartyName partyId={id} />
          {renderRemovePartyButton()}
        </CardHeader>
        <CardBody padding={isMobile ? 0 : 'initial'}>
          <Flex>
            <Grid
              templateColumns={`repeat(${numberOfColumns}, 1fr)`}
              gap={6}
              width='100%'
              marginLeft='52px'
              marginRight='16px'
              marginBottom={2}
            >
              <GridItem>
                <Heading size='sm'>Name</Heading>
              </GridItem>

              {!isMobile && (
                <GridItem>
                  <Heading size='sm'>Preferences</Heading>
                </GridItem>
              )}
            </Grid>
          </Flex>
          <GuestList
            guests={guests}
            currentGuestId={currentGuestId}
            handleGuestClick={handleGuestClick}
            setCurrentGuestId={setCurrentGuestId}
          />
          <Button
            leftIcon={<BsPersonPlusFill />}
            onClick={handleGuestCreateClicked}
            margin={2}
            variant='outline'
          >
            New Guest
          </Button>
        </CardBody>
      </Card>
      {isOpenPreference && (
        <AddPreferenceModal
          isOpen={isOpenPreference}
          onClose={onClosePreference}
          guestId={currentGuestId!}
        />
      )}
      <Modal isOpen={isOpenModal} onClose={onCloseModal}>
        <ModalOverlay />
        <AddUpdateGuest onSubmit={handleDialogClosed} partyId={id} guestId={currentGuestId} />
      </Modal>
      <DeleteConfirmationDialog
        isOpen={isOpenAlert}
        onClose={() => setIsOpenAlert(false)}
        onConfirm={handleDeleteConfirmed}
        onCancel={() => setCurrentGuestId(null)}
        alertType={alertType}
      />
    </>
  );
};

export default Party;
