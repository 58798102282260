import * as React from 'react';
import { Box } from '@chakra-ui/react';
import Header, { HEADER_HEIGHT } from './header';
import Footer, { FOOTER_HEIGHT } from './footer';
import NavigationMenu from './navigation-menu';
import DarkModeToggle from './color-mode-toggle';

export interface IPageProps {
  showNav?: boolean;
  showFooter?: boolean;
  children?: React.ReactNode;
}

const Page: React.FunctionComponent<IPageProps> = (props) => {
  const { children, showNav, showFooter } = props;
  const footerHeight = showFooter ? FOOTER_HEIGHT : 0;

  return (
    <>
      {showNav && <Header />}
      {showNav && (
        <Box position='absolute' top='1rem' right='1rem'>
          <DarkModeToggle />
        </Box>
      )}
      <Box
        minH={`calc(100vh - ${footerHeight}px - ${showNav ? `${HEADER_HEIGHT}px` : '0px'})`}
        h='min-content'
      >
        {children}
      </Box>
      {showFooter && <Footer />}
      <NavigationMenu />
    </>
  );
};

Page.defaultProps = {
  showNav: true,
  showFooter: true,
};

export default Page;
