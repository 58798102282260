import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './print.css';
import Router from './router';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import '@fontsource/indie-flower/400.css';
import theme from './theme';
import { persistor, store } from './store/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import CookieBanner from './components/cookie';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ChakraProvider theme={theme}>
          <ColorModeScript initialColorMode={theme.config.initialColorMode} />
          <CookieBanner />
          <Router />
        </ChakraProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
