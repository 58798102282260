import { Middleware, AnyAction, MiddlewareAPI, Dispatch } from '@reduxjs/toolkit';
import { PlanRequest, ValidationStatus } from 'centerpiece-algorithm-client';
import { api } from '../util/api';
import { v4 as uuidv4 } from 'uuid';
import { createStandaloneToast } from '@chakra-ui/react';
import { __ } from '../util/object.function';

export const ValidationMiddleware: Middleware = (store: MiddlewareAPI) => {
  let debounceTimeout: NodeJS.Timeout | null = null;
  const debounceTime = 500;

  return (next: Dispatch<AnyAction>) => async (action: AnyAction) => {
    next(action);

    const sliceName = action.type.split('/')[0];
    const slicesToValidate = ['guest-constraints', 'top-constraints', 'guests', 'parties', 'tops'];
    if (slicesToValidate.includes(sliceName)) {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      debounceTimeout = setTimeout(async () => {
        const state = store.getState();
        // only request if not all are empty
        if (
          !(__.IsNullOrUndefinedOrEmpty(state.guests) || __.IsNullOrUndefinedOrEmpty(state.tops))
        ) {
          const request: PlanRequest = {
            dataKey: uuidv4(),
            guestConstraints: state.guestConstraints,
            topConstraints: state.topConstraints,
            guests: state.guests,
            tops: state.tops,
            planConfig: {},
          };
          const response = await api.postValidateInputs(request);
          const validationStatus = response.data.validationStatus;

          if (
            validationStatus === ValidationStatus.invalid ||
            validationStatus === ValidationStatus.error
          ) {
            const toast = createStandaloneToast();
            toast.toast({
              position: 'bottom',
              description: response.data.message,
              status: 'error',
              variant: 'top-accent',
              isClosable: true,
            });
          }
        }
      }, debounceTime);
    }
  };
};

export default ValidationMiddleware;
