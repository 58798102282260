export const __ = {
  /**
   * Checks if the passed argument is null or undefined, or not.
   * @param argument
   * @returns
   */
  IsNullOrUndefined(argument: any): boolean {
    return argument === null || argument === undefined;
  },
  /**
   * Checks if the passed argument is null or undefined or empty, or not.
   * @param argument
   * @returns
   */
  IsNullOrUndefinedOrEmpty(argument: any): boolean {
    return (
      argument === null ||
      argument === undefined ||
      argument === '' ||
      argument.length === 0 ||
      argument.size === '' ||
      argument.size === 0
    );
  },
  /**
   * Joins an array in a beautiful style by putting special a character between the last two elements 🤙🏼
   * @param array The array of elements
   * @param lastSeparator Separator of the last and second last item
   * @param addSpaces Indicates whether to add spaces between the elements or not
   * @returns A beautiful joined string
   */
  JoinOxfordStyle(
    array: string[],
    lastSeparator: string = 'and',
    addSpaces: boolean = true
  ): string {
    if (this.IsNullOrUndefinedOrEmpty(array)) {
      return '';
    }
    if (array.length === 1) {
      return array[0];
    }
    return addSpaces
      ? `${array.slice(0, -1).join(', ')} ${lastSeparator} ${array.slice(-1)}`
      : `${array.slice(0, -1).join(',')} ${lastSeparator} ${array.slice(-1)}`;
  },

  /**
   * Capitalizes the first letter of each word in a string
   * @param input
   * @returns
   */
  CapitalizeWords(input: string): string {
    return input
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  },
};
