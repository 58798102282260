import { Button, Center, Stack, Text } from '@chakra-ui/react';
import { FOOTER_HEIGHT } from '../components/reusable/layout/footer';
import { HEADER_HEIGHT } from '../components/reusable/layout/header';
import Page from '../components/reusable/layout/page';
import { guestConstraintsActions } from '../store/slices/guest-constraints-slice';
import { guestsActions } from '../store/slices/guests-slice';
import { partiesActions } from '../store/slices/parties-slice';
import { topConstraintsActions } from '../store/slices/top-constraints-slice';
import { topsActions } from '../store/slices/tops-slice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resultActions } from '../store/slices/result-slice';

const Error: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClear = () => {
    dispatch(guestConstraintsActions.clear());
    dispatch(topConstraintsActions.clear());
    dispatch(resultActions.clear());
    dispatch(guestsActions.clear());
    dispatch(topsActions.clear());
    dispatch(partiesActions.clear());
    navigate('/');
  };

  return (
    <Page>
      {/* @David, something is weird in our general layout, i always need to scroll to see the bottom */}
      <Center h={`calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)`} flexDir='column'>
        <Text marginBottom={2}>Something went wrong</Text>
        <Stack>
          <Button variant='outline' onClick={() => window.location.reload()}>
            Refresh
          </Button>
          <Button variant='outline' onClick={() => onClear()}>
            Clear
          </Button>
        </Stack>
      </Center>
    </Page>
  );
};

export default Error;
