import React from 'react';
import { IconButton, useColorMode } from '@chakra-ui/react';
import { SunIcon, MoonIcon } from '@chakra-ui/icons';

const DarkModeToggle = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <IconButton
      className='print-hide'
      aria-label='Toggle Dark Mode'
      icon={
        colorMode === 'dark' ? (
          <SunIcon color='gray.500' boxSize='1.2rem' />
        ) : (
          <MoonIcon color='gray.500' boxSize='1.2rem' />
        )
      }
      variant='ghost'
      onClick={toggleColorMode}
    />
  );
};

export default DarkModeToggle;
