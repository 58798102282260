import { Center, Heading } from '@chakra-ui/react';
import * as React from 'react';

interface IPageHeaderProps {
  children?: React.ReactNode;
}

const PageHeader: React.FunctionComponent<IPageHeaderProps> = (props) => {
  return (
    <Center className='print-hide'>
      <Heading size='xl' pb={8}>
        {props.children}
      </Heading>
    </Center>
  );
};

export default PageHeader;
