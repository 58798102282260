import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IParty } from '../../types/party';

const initialState: Array<IParty> = [];
export const partiesSlice = createSlice({
  name: 'parties',
  initialState,
  reducers: {
    replace: (_state, action: PayloadAction<IParty[]>) => {
      return action.payload;
    },
    add: (state, action: PayloadAction<IParty>) => {
      return [action.payload, ...state];
    },
    addMultiple: (state, action: PayloadAction<IParty[]>) => {
      return [...state, ...action.payload];
    },
    delete: (state, action: PayloadAction<string>) => {
      return state.filter(party => party.id !== action.payload);
    },
    update: (state, action: PayloadAction<IParty>) => {
      return state.map(party => {
        if (party.id === action.payload.id) {
          return action.payload;
        }
        return party;
      });
    },
    updateDisplayName: (state, action: PayloadAction<IParty>) => {
      return state.map(party => {
        if (party.id === action.payload.id) {
          return {
            ...party,
            displayName: action.payload.displayName,
          };
        }
        return party;
      });
    },
    updateCommputedName: (state, action: PayloadAction<IParty>) => {
      return state.map(party => {
        if (party.id === action.payload.id) {
          return {
            ...party,
            computedName: action.payload.computedName,
          };
        }
        return party;
      });
    },
    clear: () => {
      return [];
    },
  },
});

// Action creators are generated for each case reducer function
export const partiesActions = partiesSlice.actions;

export default partiesSlice.reducer;
