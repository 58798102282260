import { EditIcon } from '@chakra-ui/icons';
import { Flex, IconButton, Tooltip, useEditableControls } from '@chakra-ui/react';
import * as React from 'react';

export interface IEditableControlProps {
  tooltipLabel: string;
}

const EditableControls: React.FunctionComponent<IEditableControlProps> = (props) => {
  const { tooltipLabel } = props;
  const { isEditing, getEditButtonProps } = useEditableControls();

  if (isEditing) {
    return null;
  }

  return (
    <Flex justifyContent='center'>
      <Tooltip hasArrow label={tooltipLabel} placement='right'>
        <IconButton
          aria-label='edit-button'
          variant='ghost'
          size='xs'
          icon={<EditIcon />}
          {...getEditButtonProps()}
        />
      </Tooltip>
    </Flex>
  );
};

export default EditableControls;
