// PayPalDonateButton.tsx
import React from 'react';

const PayPalDonateButton: React.FC = () => (
  <form className='print-hide' action='https://www.paypal.com/donate' method='post' target='_top'>
    <input type='hidden' name='hosted_button_id' value='EB6Y6NCJBFHKG' />
    <input
      type='image'
      src='https://www.paypalobjects.com/en_US/i/btn/btn_donate_SM.gif'
      style={{ border: '0' }} // Set border to 0 using style attribute
      name='submit'
      title='PayPal - The safer, easier way to pay online!'
      alt='Donate with PayPal button'
    />
    <img alt='' src='https://www.paypal.com/en_DE/i/scr/pixel.gif' width='1' height='1' />
  </form>
);

export default PayPalDonateButton;
