import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { GuestConstraint } from 'centerpiece-algorithm-client';
import { guestsActions } from './guests-slice';

const initialState: Array<GuestConstraint> = [];
export const guestConstraintsSlice = createSlice({
  name: 'guest-constraints',
  initialState,
  reducers: {
    replace: (state, action: PayloadAction<GuestConstraint[]>) => {
      return action.payload;
    },
    add: (state, action: PayloadAction<GuestConstraint>) => {
      return [...state, action.payload];
    },
    addMultiple: (state, action: PayloadAction<GuestConstraint[]>) => {
      return [...state, ...action.payload];
    },
    delete: (state, action: PayloadAction<{ guestId1: string; guestId2: string }>) => {
      return state.filter(
        constraint =>
          constraint.guestId1 !== action.payload.guestId1 ||
          constraint.guestId2 !== action.payload.guestId2
      );
    },
    deleteForGuest: (state, action: PayloadAction<string>) => {
      return state.filter(
        constraint =>
          constraint.guestId1 !== action.payload && constraint.guestId2 !== action.payload
      );
    },
    clear: () => {
      return [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(guestsActions.clear, () => {
        return [];
      })
      .addCase(guestsActions.replace, () => {
        return [];
      })
      .addCase(guestsActions.delete, (state, action: PayloadAction<string>) => {
        return state.filter(
          constraint =>
            constraint.guestId1 !== action.payload && constraint.guestId2 !== action.payload
        );
      });
  },
});

// Action creators are generated for each case reducer function
export const guestConstraintsActions = guestConstraintsSlice.actions;

export default guestConstraintsSlice.reducer;
