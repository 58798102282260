import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { RootState } from '../../store/store';
import { guestConstraintsActions } from '../../store/slices/guest-constraints-slice';
import { Guest, GuestConstraint } from 'centerpiece-algorithm-client';
import { DeleteIcon } from '@chakra-ui/icons';
import { EnumHelper } from '../../util/enum.function';
import useMobile from '../../hooks/useMobile';
import { TbSettingsPlus } from 'react-icons/tb';
import { getIconByGuestConstraintType } from '../reusable/custom-arrow-icon';
import AddPreferenceModal from './add-preference-modal';

interface IGuestConstraintsProps {
  guestId: string;
  onSubmit?: () => void;
}

// Create a selector function to get the raw state.guestConstraints
const rawGuestConstraintsSelector = (state: RootState) => state.guestConstraints;
// Create a memoized selector using createSelector
export const allGuestConstraintsSelector = createSelector(
  [
    rawGuestConstraintsSelector,
    (state: RootState, guestsOfSamePartyCurrentGuest: Set<string>) => guestsOfSamePartyCurrentGuest,
  ],
  (guestConstraints, guestsOfSamePartyCurrentGuest) =>
    guestConstraints.filter(
      (constraint) =>
        guestsOfSamePartyCurrentGuest.has(constraint.guestId1!) ||
        guestsOfSamePartyCurrentGuest.has(constraint.guestId2!)
    )
);
// And another memoized selector for guestConstraints of the current guest, needed to display their constraints
export const guestConstraintsSelector = createSelector(
  [rawGuestConstraintsSelector, (state: RootState, guestId: string) => guestId],
  (guestConstraints, guestId) =>
    guestConstraints.filter(
      (constraint) => constraint.guestId1 === guestId || constraint.guestId2 === guestId
    )
);

const GuestConstraints: React.FunctionComponent<IGuestConstraintsProps> = (props) => {
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const { isOpen, onClose, onOpen } = useDisclosure();

  // all parties and all guests
  // const parties = useSelector((state: RootState) => state.parties);
  const guests = useSelector((state: RootState) => state.guests);

  // current guest
  const currentGuest: Guest = guests.find((guest) => guest.id === props.guestId)!;

  // the constraints of the current guest, needed to display his or her constraints
  const guestConstraints = useSelector((state: RootState) =>
    guestConstraintsSelector(state, currentGuest.id)
  );

  const handleRemove = (guestId1: string, guestId2: string) => {
    dispatch(guestConstraintsActions.delete({ guestId1, guestId2 }));
  };

  const renderConstraintType = (constraint: GuestConstraint) => {
    if (isMobile) {
      return (
        <HStack marginLeft={2}>
          {getIconByGuestConstraintType(constraint.guestConstraintType!)}
        </HStack>
      );
    } else {
      return (
        <HStack spacing={4}>
          {getIconByGuestConstraintType(constraint.guestConstraintType!)}
          <Box>
            {EnumHelper.GetConstraintTypeDisplayName(constraint.guestConstraintType!, false)}
          </Box>
        </HStack>
      );
    }
  };

  const renderButtons = () => {
    if (currentGuest.isSpecial) {
      return null;
    }
    if (isMobile) {
      return (
        <IconButton
          icon={<TbSettingsPlus />}
          variant='solid'
          onClick={onOpen}
          aria-label={'Add preference'}
          alignSelf='flex-end'
          size={'sm'}
        ></IconButton>
      );
    } else {
      return (
        <Button alignSelf='flex-end' leftIcon={<TbSettingsPlus />} variant='solid' onClick={onOpen}>
          Add Preference
        </Button>
      );
    }
  };

  return (
    <>
      <Grid templateColumns='repeat(5, 1fr)' gap={2} width='100%'>
        {guestConstraints.map((constraint) => (
          <React.Fragment key={`${constraint.guestId1}${constraint.guestId2}`}>
            {/* CONSTRAINT TYPE */}
            <GridItem colSpan={isMobile ? 1 : 2}>{renderConstraintType(constraint)}</GridItem>
            {/* GUEST CONSTRAINT WITH NAME */}
            <GridItem colSpan={isMobile ? 3 : 2}>
              {
                (
                  guests.find(
                    (guest) =>
                      guest.id ===
                      (constraint.guestId1 === props.guestId
                        ? constraint.guestId2
                        : constraint.guestId1)
                  ) as Guest
                ).name
              }
            </GridItem>
            <GridItem width='100%' display='flex' alignItems='center' justifyContent='right'>
              <IconButton
                aria-label='Remove Preference'
                icon={<DeleteIcon />}
                variant='solid'
                color='black'
                borderColor='pastelRed'
                background='pastelRed'
                size={'sm'}
                onClick={() => handleRemove(constraint.guestId1 || '', constraint.guestId2 || '')}
              />
            </GridItem>
          </React.Fragment>
        ))}
      </Grid>
      <Flex marginTop={2} justifyContent='center'>
        {renderButtons()}
      </Flex>
      {isOpen && <AddPreferenceModal isOpen={isOpen} onClose={onClose} guestId={props.guestId} />}
    </>
  );
};

export default GuestConstraints;
